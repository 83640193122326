import React, { useMemo } from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { Tooltip as ReactTooltip } from "react-tooltip"

import { tooltips } from "../../constants/tooltips"

const PARAM_COLUMN = 6

const SuperResolution = ({ parameter, onChange }) => {
  const models = useMemo(() => ["Stable_v1.0", "Dev_v1.2"], [])
  const model = parameter.model
  const scale = parameter.scale

  const onChangeScale = (e) => {
    let number = parseInt(e.target.value.replace(/^0+/, ""), 10)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 1), 8)
    onChange({ scale: number })
  }

  return (
    <div className="w-100">
      <Row
        className="row-parameter"
        data-tooltip-content={tooltips.sr_p1}
        data-tooltip-delay-show={tooltips.delay_show_timeout}
        id="sr-tool-p1"
      >
        <Col xs={PARAM_COLUMN}> Model </Col>
        <Col>
          <Dropdown>
            <Dropdown.Toggle
              className="dropdown-font custom-btn"
              id="dropdown-basic"
              size="sm"
              variant="info"
            >
              {model}
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-font" style={{ margin: 0 }}>
              {models.map((_model) => (
                <Dropdown.Item
                  key={_model}
                  onClick={() => onChange({ model: _model })}
                >
                  {" "}
                  {_model}{" "}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row
        className="row-parameter"
        data-tooltip-content={tooltips.sr_p2}
        data-tooltip-delay-show={tooltips.delay_show_timeout}
        id="sr-tool-p2"
      >
        <Col xs={PARAM_COLUMN}> Scale </Col>
        <Col>
          <input
            className="number-parameter"
            onChange={onChangeScale}
            onFocus={(e) => {
              e.target.select()
            }}
            pattern="[0-9]"
            type="number"
            value={scale}
          />
        </Col>
      </Row>

      <ReactTooltip anchorId="sr-tool-p1" className="tooltip-msg" />
      <ReactTooltip anchorId="sr-tool-p2" className="tooltip-msg" />
    </div>
  )
}

export default SuperResolution
