import PropTypes from "prop-types"
import React from "react"

import PMToolView from "./PMTool.view"

const PMTool = ({ project }) => {
  return <PMToolView project={project} />
}

PMTool.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
}
export default PMTool
