import React, { createContext, useCallback, useMemo, useState } from "react"

const metaImageDefault = {
  nx: 200,
  ny: 200,
  nz: 800,
}

export const WorkflowContext = createContext({})

export const WorkflowContextProvider = ({ children }) => {
  const [metaImage, setImageMeta] = useState(metaImageDefault)

  const metaImageString = useMemo(() => JSON.stringify(metaImage), [metaImage])
  const handleSetImageMeta = useCallback((_data) => setImageMeta(_data), [])

  const workflowContextValue = useMemo(() => {
    return {
      metaImage: JSON.parse(metaImageString),
      handleSetImageMeta,
    }
  }, [metaImageString, handleSetImageMeta])

  return (
    <WorkflowContext.Provider value={workflowContextValue}>
      {children}
    </WorkflowContext.Provider>
  )
}
