import "react-multi-email/dist/style.css"

import * as React from "react"
import { ReactMultiEmail } from "react-multi-email"

const EmailHolder = ({ users, setUsers }) => {
  return (
    <div>
      <ReactMultiEmail
        autoFocus={true}
        emails={users}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              <div data-tag-item>{email}</div>
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          )
        }}
        onChange={(_emails) => {
          setUsers(_emails)
        }}
        placeholder="Users"
      />
    </div>
  )
}

export default EmailHolder
