import React, { memo } from "react"

import { STATUS_RUNNING, STATUS_WAITING } from "../../../../constants/jobnames"
import { useNetworkTool } from "../../NetworkTool.hook"

const LaunchButton = () => {
  const { job, readyToLaunch, launchJob, killJob } = useNetworkTool()

  if (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) {
    return (
      <button
        className="btn btn-success btn-block"
        disabled={!readyToLaunch}
        onClick={launchJob}
        type="button"
      >
        Launch
      </button>
    )
  }

  return (
    <button
      className="btn btn-warning btn-block"
      onClick={killJob}
      type="button"
    >
      Cancel
    </button>
  )
}

export default memo(LaunchButton)
