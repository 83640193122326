export const SEGMENTATION_JOB = "segmentation"
export const ADJUSTMENT_JOB = "adjustment"
export const SUPERRESOLUTION_JOB = "superresolution"
export const SGAN_JOB = "sgan"
export const UPLOAD_JOB = "upload"
export const EXTRA_JOB = "extra"
export const CROP_JOB = "crop"
export const NETWORK_JOB = "netex"
export const NETSIM_JOB = "netsim"
export const PM_JOB = "pormap"
export const EXPERIMENTAL_JOB = "experimental"
export const EXP_DELETE_JOB = "exp_delete_job"
export const EXP_VIEW_JOB = "exp_view_job"
export const STATUS_FINISHED = "finished"
export const STATUS_FAILED = "failed"
export const STATUS_RUNNING = "running"
export const STATUS_UNKNOWN = "unknown"
export const STATUS_KILLED = "killed"
export const STATUS_WAITING = "waiting"

export const isValidJob = (jobName) => {
  switch (jobName) {
    case ADJUSTMENT_JOB:
      return true
    case SEGMENTATION_JOB:
      return true
    case SUPERRESOLUTION_JOB:
      return true
    case SGAN_JOB:
      return true
    case UPLOAD_JOB:
      return true
    case EXTRA_JOB:
      return true
    case CROP_JOB:
      return true
    case NETWORK_JOB:
      return true
    case NETSIM_JOB:
      return true
    case EXPERIMENTAL_JOB:
      return true
    case EXP_DELETE_JOB:
      return true
    case EXP_VIEW_JOB:
      return true
    case PM_JOB:
      return true
    default:
      return false
  }
}
