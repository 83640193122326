/* eslint-disable react/react-in-jsx-scope */
import React from "react"
import { Col, Row } from "react-bootstrap"
import { Check, X } from "react-feather"

import { useEditRockType } from "./EditRockType.hook"
import styles from "./style.module.css"

const EditRockType = ({ onClose, pid, rId, rockType }) => {
  const {
    rockTypeProperties,
    handleChangeRockTypeProperty,
    handleUpdateRockType,
  } = useEditRockType({
    pid,
    rId,
    onClose,
    rockType,
  })
  return (
    <div className={styles.editRockType}>
      <div className={styles.container}>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>Name</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className="number-parameter2"
                name="name"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => e.target.select()}
                type="text"
                value={rockTypeProperties.name}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>Index</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="label"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                type="number"
                value={rockTypeProperties.label}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>a</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="a"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                step={0.1}
                type="number"
                value={rockTypeProperties.a}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>b</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="b"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                step={0.1}
                type="number"
                value={rockTypeProperties.b}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>aMin</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="aMin"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                step={0.1}
                type="number"
                value={rockTypeProperties.aMin}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>aMax</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="aMax"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                step={0.1}
                type="number"
                value={rockTypeProperties.aMax}
              />
            </div>
          </Col>
        </Row>
        <Row className="row-parameter" style={{ width: "100%" }}>
          <Col xs={8}>cemExp</Col>
          <Col>
            <div className={styles.rockTypeInputContainer}>
              <input
                className={`number-parameter2`}
                name="cemExp"
                onChange={handleChangeRockTypeProperty}
                onFocus={(e) => {
                  e.target.select()
                }}
                step={0.1}
                type="number"
                value={rockTypeProperties.cemExp}
              />
            </div>
          </Col>
        </Row>
        <div className={styles.actions}>
          <button
            className={`btn btn-primary ${styles["button"]}`}
            onClick={handleUpdateRockType}
            type="button"
          >
            <Check />
          </button>
          <button
            className={`btn btn-outline-primary ${styles["button"]}`}
            onClick={onClose}
          >
            <X />
          </button>
        </div>
      </div>
    </div>
  )
}

export default EditRockType
