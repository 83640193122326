import React from "react"
import ReactCrop from "react-image-crop"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import { PlaneSelector } from "../../PlaneSelector"
import VerticalSlider from "../../VerticalSlider/VerticalSlider"
import ImageWrapper from "../ImageWrapper/ImageWrapper.view"
import usePrintImageCrop from "./PrintImageCrop.hook"

const PrintImageCrop = (props) => {
  const {
    imagePlane,
    imageName,
    disabled,
    sliderState,
    disableSlider,
    setImagePlane,
    handleSetCrop,
    getCropValuesByPlane,
    handleUpdateSliderState,
  } = usePrintImageCrop(props)

  return (
    <ImageWrapper title="Image Crop Viewer">
      <div className="image-box" disabled={disabled}>
        <div className="image-frame-wrapper">
          {!disabled ? (
            <TransformWrapper
              centerOnInit={true}
              centerZoomedOut={true}
              doubleClick={{
                disabled: true,
                mode: "reset",
              }}
            >
              {() => (
                <div className="image-frame">
                  {!disableSlider && (
                    <div className="slider-holder">
                      <VerticalSlider onChange={handleUpdateSliderState}
                       useDebounced={true}
                      />
                    </div>
                  )}
                  <TransformComponent>
                  <ReactCrop
                      className="image"
                      crop={getCropValuesByPlane()}
                      onChange={(_, percentCrop) => handleSetCrop(percentCrop)}
                    >
                    <img
                      alt="Cropped preview"
                      className="image"
                      src={imageName}
                      style={{
                        filter: `brightness(${sliderState.brightness}) contrast(${sliderState.contrast})`,
                      }}
                    />
                    </ReactCrop>
                  </TransformComponent>
                </div>
              )}
            </TransformWrapper>
          ) : (
            <div className="image-frame" disabled={disabled}>
              <img alt="new" className="image" disabled={true} src={require("../../../images/noImage.png")} />
            </div>
          )}
        </div>
      </div>
      <PlaneSelector currentCutPlane={{ pos: imagePlane }} updatePlane={({ pos }) => setImagePlane(pos)} />
    </ImageWrapper>
  )
}

export default PrintImageCrop
