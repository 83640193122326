import "./Parameters.css"

import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { connect } from "react-redux"
import { Progress } from "reactstrap"

import {
  doJob,
  getJobStatus,
  getProject,
  killJob,
} from "../actions/projectSlice"
import ImageFolders from "../components/ImageFolders"
import PrintImageSR from "../components/ImagePreview/PrintImageSR/PrintImageSR.view"
import {
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../constants/jobnames"
import ConsoleHelper from "../helpers/ConsoleHelper"
import useJobNotification from "../hooks/useJobNotification"

const POLLING_TIME = 3000
const INPUT_TYPE = "16b"

function GenericTool(props) {
  const [outputName, setOutputName] = useState("")
  const [viewID, setViewID] = useState("")
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })

  const intervalId = useRef(null)
  const isJobFinished = useRef(false)

  const memoizedImage = useMemo(() => {
    return props.project.images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.project.images)])

  const memoJobType = useMemo(() => {
    return props.jobType
  }, [props.jobType])

  const memoizedPid = useMemo(() => {
    return props.project?.pid || null
  }, [props.project?.pid])

  const selectedImage = useMemo(() => {
    return memoizedImage[viewID]
  }, [viewID, memoizedImage])

  const sendUpdateRequest = useCallback(
    (iid, jid) => {
      // ConsoleHelper("Sending job request ", jid)
      if (iid !== "" && iid !== undefined && jid !== undefined) {
        const payload = {
          pid: props.project.pid,
          iid,
          jid,
          jobname: props.jobType,
        }
        props.getJobStatus(payload)
      } else {
        ConsoleHelper("Not defined JID, [" + jid + "]")
      }
    },
    [props],
  )

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      setViewID(index)
      let image = props.project.images[index]
      if (props.onResetParameter) {
        const jobParameter = image?.jobs?.[props.jobType]?.parameters || null
        props.onResetParameter(jobParameter)
      }
      setOutputName(props.prefix + image.name)
      if (image.jobs !== undefined && image.jobs[props.jobType] !== undefined) {
        setJob(image.jobs[props.jobType])
      } else {
        setJob({ status: STATUS_UNKNOWN, progress: 0 })
      }
      isJobFinished.current = false
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.jobType, props.prefix, props.project?.images, sendUpdateRequest],
  )

  useEffect(() => {
    let images = props.project.images
    let nImages = Object.keys(images).filter(
      (iid) => images[iid].type === INPUT_TYPE,
    )
    if (nImages.length > 0) changeView(nImages[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let image = props.project.images[viewID]
    if (
      image !== undefined &&
      image.jobs !== undefined &&
      image.jobs[props.jobType] !== undefined
    ) {
      if (
        job.status !== image.jobs[props.jobType].status ||
        job.progress !== image.jobs[props.jobType].progress
      ) {
        setJob(image.jobs[props.jobType])
      }
    }
  }, [viewID, job, props?.jobType, props?.project?.images])

  useJobNotification({ job })

  const pollApi = useCallback(() => {
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        clearInterval(intervalId.current)
      } else {
        sendUpdateRequest(viewID, job.jid)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job, props, sendUpdateRequest, viewID])

  useEffect(() => {
    if (!isJobFinished.current && job.status === STATUS_FINISHED) {
      isJobFinished.current = true // warning dont remove with current, if you remove infinity get current project
      props.getProject(props.project.pid)
      clearInterval(intervalId.current)
      return
    }

    if (job?.status === STATUS_RUNNING || job?.status === STATUS_WAITING) {
      isJobFinished.current = false
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }

    return () => clearInterval(intervalId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.status, pollApi])

  const readyToLaunch = useMemo(() => {
    if (
      selectedImage != null &&
      job.status !== STATUS_RUNNING &&
      job.status !== STATUS_WAITING &&
      outputName.length > 0
    )
      return true
    return false
  }, [selectedImage, job.status, outputName])

  const onChangeImage = useCallback(
    (_value) => {
      changeView(_value)
    },
    [changeView],
  )

  const onChangeOutput = (event) => {
    setOutputName(event.currentTarget.value)
  }

  const launchJob = useCallback(() => {
    let properties = props.getLaunchProps()
    properties = {
      ...properties,
      out_path: outputName,
    }
    ConsoleHelper("Launching JoB", properties)
    let payload = {
      pid: memoizedPid,
      iid: selectedImage.iid,
      jobname: memoJobType,
      data: properties,
    }
    props.doJob(payload)
    // intervalId.current = setInterval(pollApi, POLLING_TIME)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    outputName,
    memoizedPid,
    selectedImage?.iid,
    memoJobType,
    props?.getLaunchProps,
    props?.doJob,
  ])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const killJobProps = useCallback(props.killJob, [props.killJob])

  const killJob = useCallback(() => {
    clearInterval(intervalId.current)
    if (job.jid !== undefined) {
      const payload = {
        pid: memoizedPid,
        iid: viewID,
        jid: job.jid,
        jobname: memoJobType,
      }
      ConsoleHelper("Killing JoB: " + payload)
      killJobProps(payload)
    } else {
      ConsoleHelper("Not defined Cancel JID, [" + job.jid + "]")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(job), memoizedPid, memoJobType, viewID, killJobProps])

  const LaunchButton = () => {
    let properties = props.getLaunchProps()
    if (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING)
      if (properties.adjust === true && job.status === STATUS_FINISHED)
        return (
          <button
            className="btn btn-info btn-block"
            disabled={false}
            onClick={launchJob}
            type="button"
          >
            {" "}
            Re-Adjust{" "}
          </button>
        )
      else
        return (
          <button
            className="btn btn-success btn-block"
            disabled={!readyToLaunch}
            onClick={launchJob}
            type="button"
          >
            {" "}
            Launch{" "}
          </button>
        )
    else
      return (
        <button
          className="btn btn-warning btn-block"
          onClick={killJob}
          type="button"
        >
          {" "}
          Cancel{" "}
        </button>
      )
  }

  let jid = undefined
  let iid = undefined
  if (job.status === STATUS_FINISHED) {
    jid = job.jid
    iid = viewID
  }

  return (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">{props.children}</div>
          <h4 className="subtitle"> Input Image [{INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={INPUT_TYPE}
            images={memoizedImage}
            jobType={memoJobType}
            onChangeImage={onChangeImage}
            pid={memoizedPid}
            viewID={viewID}
          />
          <h4 className="subtitle"> Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={onChangeOutput}
              placeholder=" Output File Name"
              type="text"
              value={outputName}
            />
          </div>
        </div>
        {props.extraComponent !== undefined && (
          <props.extraComponent
            disabled={job.status !== STATUS_FINISHED}
            iid={iid}
            jid={jid}
          />
        )}
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton />
      </div>

      <div className="toolResultWrapper">
        <PrintImageSR
          job={job}
          pid={props.project?.pid || null}
          selectedImage={selectedImage}
        />
      </div>
    </div>
  )
}

GenericTool.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
  jobType: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(
  GenericTool,
)
