import React, { memo } from "react"

import { formatPermValue } from "../../../../utils/formatter"
import { useNetworkTool } from "../../NetworkTool.hook"

const NetworkData = () => {
  const { jobResult } = useNetworkTool()

  if (!jobResult) return <h5 className="no-results-netex">No Results Yet</h5>

  const network = jobResult.Network
  const properties = jobResult.Properties
  return (
    <>
      <h4 className="subtitle mb-4"> Network</h4>
      <div className="parameter mx-0">
        <label className="result-label">
          Nodes:
          <label className="result">{network.Nodes}</label>
        </label>
        <label className="result-label">
          Links:
          <label className="result">{network.Links}</label>
        </label>
        <label className="result-label">
          AspectRatio:
          <label className="result">{network.AspectRatio.toFixed(2)}</label>
        </label>
        <label className="result-label">
          CoordNumber:
          <label className="result">
            {network.CoordinationNumber.toFixed(2)}
          </label>
        </label>
      </div>
      <div className="mt-5"></div>
      <h4 className="subtitle mb-4"> Properties</h4>
      <div className="parameter mx-0">
        <label className="result-label">
          TotalPorosity [%]:
          <label className="result">
            {(properties.TotalPorosity * 100).toFixed(2)}
          </label>
        </label>
        <label className="result-label">
          OpenPorosity [%]:
          <label className="result">
            {(properties.OpenPorosity * 100).toFixed(2)}
          </label>
        </label>
        {properties.PermX && properties.PermY && properties.PermZ ? (
          <label className="result-label">
            Perm. (X-Y-Z) [mD]: (
            <label className="result">
              {formatPermValue(properties.PermX)} -{" "}
              {formatPermValue(properties.PermY)} -{" "}
              {formatPermValue(properties.PermZ)}
            </label>
            )
          </label>
        ) : null}
        {properties.FFX && properties.FFY && properties.FFZ ? (
          <label className="result-label">
            FormFactor (X-Y-Z): (
            <label className="result">
              {properties.FFX.toFixed(2)} - {properties.FFY.toFixed(2)} -{" "}
              {properties.FFZ.toFixed(2)}
            </label>
            )
          </label>
        ) : null}
        {properties.TortuosityX &&
        properties.TortuosityY &&
        properties.TortuosityZ ? (
          <label className="result-label">
            Tortuosity (X,Y,Z): (
            <label className="result">
              {properties.TortuosityX.toFixed(2)} -{" "}
              {properties.TortuosityY.toFixed(2)} -{" "}
              {properties.TortuosityZ.toFixed(2)}
            </label>
            )
          </label>
        ) : null}
      </div>
    </>
  )
}

export default memo(NetworkData)
