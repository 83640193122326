import React from 'react'

import { STATUS_FINISHED } from '../../../constants/jobnames'
import ImageWrapper from '../ImageWrapper/ImageWrapper.view'
import { PrintImageSRProvider } from './PrintImageSR.context'
import PrintImageSRItem from './PrintImageSRItem/PrintImageSRItem.view'

const PrintImageSR = ({ 
    job,
    pid,
    selectedImage
 }) => {

  return (
    <div className="images-container">
        <ImageWrapper 
            job={job}
            title="Input"
            >
            <PrintImageSRItem
                isShowOutputImage={false}
                job={selectedImage?.jobs?.extra}
                pid={pid}
                selectedImage={selectedImage}
            />
        </ImageWrapper>
        <ImageWrapper 
            job={job}
            title="Output"
            >
            {
                job?.status === STATUS_FINISHED ?
                <PrintImageSRItem
                    isShowOutputImage={true}
                    job={job}
                    pid={pid}
                    selectedImage={selectedImage}
                /> 
                :
                <PrintImageSRItem />
            }
        </ImageWrapper>
    </div>
  )
}

const PrintImageSRContainer = (props) => {
    return (
        <PrintImageSRProvider>
            <PrintImageSR {...props} />
        </PrintImageSRProvider>
    )
}

export default PrintImageSRContainer