import "./Parameters.css"

import Slider from "@mui/material/Slider"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Progress } from "reactstrap"

import {
  doJob,
  getJobStatus,
  getProject,
  killJob,
} from "../actions/projectSlice"
import { LaunchButton } from "../components"
import ImageFolders from "../components/ImageFolders"
import PrintImageSeg from "../components/ImagePreview/PrintImageSeg/PrintImageSeg.view"
import {
  ADJUSTMENT_JOB,
  SEGMENTATION_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../constants/jobnames"
import { tooltips } from "../constants/tooltips"
import { getLocalStorage, setLocalStorage } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"
import useJobNotification from "../hooks/useJobNotification"
import { killJobFn, sendUpdateRequestFn } from "../utils/jobs"

const INPUT_TYPE = "16b"
const JOB_TYPE = SEGMENTATION_JOB
const JOB_PREFIX = "seg_"
const ADJUST_JOB_TYPE = ADJUSTMENT_JOB
const POLLING_TIME = 3000
const ADJUST_POLLING_TIME = 300
const PARAM_COLUMN = 6

function SegmentationTool(props) {
  const memoizedImages = useMemo(() => {
    return props.project.images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.project.images)])

  const memoPid = useMemo(() => {
    return props.project?.pid || null
  }, [props.project?.pid])

  const [model, setModel] = useState("StableST_v1.0")
  const [thresholdMin, setThresholdMin] = useState(
    parseFloat(getLocalStorage("Seg_threshold_min", 0.0)),
  )
  const [thresholdMax, setThresholdMax] = useState(
    parseFloat(getLocalStorage("Seg_threshold_max", 0.0)),
  )
  const [adjust, setAdjust] = useState(false)
  const [adjustValues, setAdjustValues] = useState([0, 0, 0])
  const [outputName, setOutputName] = useState("")
  const [viewID, setViewID] = useState(() => {
    const nImages = Object.keys(memoizedImages).filter(
      (iid) => memoizedImages[iid].type === INPUT_TYPE,
    )
    if (nImages.length > 0) {
      setOutputName(JOB_PREFIX + memoizedImages[nImages[0]].name)
      return nImages[0]
    }
    return ""
  })
  const [sliderResetCount, setSliderResetCount] = useState(0)
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })
  const [adjustJob, setAdjustJob] = useState({
    status: STATUS_UNKNOWN,
    progress: 0,
  })
  const [faceImage, setFaceImage] = useState("xy")
  const intervalId = useRef(null)
  const intervalIdAdjust = useRef(null)

  const selectedImage = useMemo(() => {
    return memoizedImages?.[viewID] || null
  }, [memoizedImages, viewID])

  const adjustmentImage = useMemo(() => {
    if (!selectedImage?.iid) return null
    const adjustmentViewID = JOB_PREFIX + selectedImage.iid

    if (selectedImage?.jobs?.[ADJUST_JOB_TYPE]) {
      return {
        iid: adjustmentViewID,
        jobs: {
          [ADJUST_JOB_TYPE]: selectedImage?.jobs?.[ADJUST_JOB_TYPE],
        },
      }
    }

    return memoizedImages?.[adjustmentViewID] || null
  }, [memoizedImages, selectedImage])

  const sendUpdateRequest = sendUpdateRequestFn(props.getJobStatus)

  const resetParameter = ({ min, max, mode }) => {
    setThresholdMin(min || 0.0)
    setThresholdMax(max || 0.0)
    setModel(mode || "StableST_v1.0")
  }

  const pollApi = useCallback(() => {
    // ConsoleHelper("JobStatus Polling ", job)
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        clearInterval(intervalId.current)
      } else {
        sendUpdateRequest(props.project.pid, viewID, job.jid, JOB_TYPE)
      }
    }
  }, [job, props, sendUpdateRequest, viewID])

  const isJobFinished = useRef(false)
  const isJobAdjustFinished = useRef(false)

  useEffect(() => {
    if (!isJobFinished.current && job.status === STATUS_FINISHED) {
      isJobFinished.current = true // warning dont remove with current, if you remove infinity get current project
      props.getProject(props.project.pid)
      clearInterval(intervalId.current)
      return
    }

    if (job?.status === STATUS_RUNNING || job?.status === STATUS_WAITING) {
      isJobFinished.current = false
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }

    return () => clearInterval(intervalId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.status, pollApi])

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      clearInterval(intervalIdAdjust.current)
      setViewID(index)
      const image = memoizedImages[index]
      if (image.jobs !== undefined && image.jobs[JOB_TYPE] !== undefined) {
        const jobSegmentation = image.jobs[JOB_TYPE]
        setJob(jobSegmentation)
        resetParameter({
          min: jobSegmentation?.parameters?.threshold_bot,
          max: jobSegmentation?.parameters?.threshold_top,
          mode: jobSegmentation?.parameters?.model,
        })
      } else {
        setJob({ status: STATUS_UNKNOWN, progress: 0 })
        resetParameter({
          min: 0.0,
          max: 0.0,
          mode: "StableST_v1.0",
        })
      }

      setOutputName(JOB_PREFIX + image.name)
      setAdjust(false)
      setSliderResetCount(sliderResetCount + 1)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pollApi, props, sendUpdateRequest, sliderResetCount],
  )

  const pollApiAdjust = () => {
    if (Object.keys(adjustJob).length) {
      sendUpdateRequest(
        props.project.pid,
        viewID,
        adjustJob.jid,
        ADJUST_JOB_TYPE,
      )
    }
  }

  useEffect(() => {
    const _job = selectedImage?.jobs?.[JOB_TYPE]
    if (!_job?.status && !_job?.progress) {
      setJob({ status: STATUS_UNKNOWN, progress: 0 })
    } else if (
      job?.status !== _job?.status ||
      job?.progress !== _job?.progress
    ) {
      setJob(_job)
    }
    // eslint-disable-next-line
  }, [selectedImage])

  useJobNotification({ job })

  useEffect(() => {
    if (!isJobAdjustFinished.current && adjustJob?.status === STATUS_FINISHED) {
      isJobAdjustFinished.current = true // warning dont remove with current, if you remove infinity get current project
      props.getProject(props.project.pid)
      clearInterval(intervalIdAdjust.current)
      return
    }

    if (
      adjustJob?.status === STATUS_RUNNING ||
      adjustJob?.status === STATUS_WAITING
    ) {
      isJobAdjustFinished.current = false
      intervalIdAdjust.current = setInterval(pollApiAdjust, ADJUST_POLLING_TIME)
    }

    return () => clearInterval(intervalIdAdjust.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adjustJob])

  useEffect(() => {
    if (!adjustmentImage) {
      setAdjustJob({ status: STATUS_UNKNOWN, progress: 0 })
    } else {
      const ___adjustJob = adjustmentImage?.jobs?.[ADJUST_JOB_TYPE]
      const adjustmentParameter = ___adjustJob?.parameters

      let adjustValuesDefault = [0, 0, 0]
      if (adjustmentParameter?.pore_micro)
        adjustValuesDefault[0] = adjustmentParameter?.pore_micro
      if (adjustmentParameter?.pore_solid)
        adjustValuesDefault[1] = adjustmentParameter?.pore_solid
      if (adjustmentParameter?.micro_solid)
        adjustValuesDefault[2] = adjustmentParameter?.micro_solid

      setAdjustJob(___adjustJob)
      setAdjustValues(adjustValuesDefault)
    }
  }, [adjustmentImage, adjustJob?.status])

  const readyToLaunch = useMemo(() => {
    if (adjust && adjustJob) {
      return !(
        adjustJob.status === STATUS_RUNNING ||
        adjustJob.status === STATUS_WAITING
      )
    }
    return (
      selectedImage !== null &&
      job?.status !== STATUS_RUNNING &&
      job?.status !== STATUS_WAITING &&
      outputName.length > 0
    )
  }, [outputName, job?.status, selectedImage, adjust, adjustJob])

  const onChangeImage = useCallback(
    (_value) => {
      changeView(_value)
    },
    [changeView],
  )

  const onChangeOutput = (event) => {
    setOutputName(event.currentTarget.value)
  }

  const getLaunchProps = () => {
    let confimap = false
    if (adjust === true && job?.status === STATUS_FINISHED) confimap = true
    return {
      model,
      thresholdMin,
      thresholdMax,
      adjust,
      adjustValues,
      confimap,
    }
  }

  const launchJob = () => {
    let properties = getLaunchProps()
    properties = {
      ...properties,
      out_path: outputName,
    }
    ConsoleHelper("Launching JoB", properties)
    let payload = {
      pid: props.project.pid,
      iid: selectedImage.iid,
      jobname: JOB_TYPE,
      data: properties,
    }
    props.doJob(payload)
    intervalId.current = setInterval(pollApi, POLLING_TIME)
    setAdjustJob({ status: STATUS_UNKNOWN, progress: 0 })
    setAdjust(false)
  }

  const killJob = () =>
    killJobFn(props.killJob)(
      props.project.pid,
      viewID,
      job.jid,
      JOB_TYPE,
      intervalId.current,
    )

  const onChange = (e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), 1.0)

    if (e.target.id === "min") {
      const newMin = Math.min(number, thresholdMax)
      setThresholdMin(newMin)
      setLocalStorage("Seg_threshold_min", newMin)
    }
    if (e.target.id === "max") {
      if (number < thresholdMin) {
        setThresholdMin(number)
        setLocalStorage("Seg_threshold_min", number)
      }
      setThresholdMax(number)
      setLocalStorage("Seg_threshold_max", number)
    }
  }

  const onStopSlider = (newValue, index, iid, jid) => {
    ConsoleHelper("Adjusting from slider stop " + newValue + "/" + index)
    if (iid !== undefined && jid !== undefined) {
      let values = adjustValues
      values[index] = newValue[0]
      setAdjustValues(values)

      let data = { values, parent_jid: jid, face: faceImage }
      let payload = {
        pid: props.project.pid,
        jobname: ADJUSTMENT_JOB,
        iid,
        data,
      }
      props.doJob(payload)
    }
  }

  const customMarks = (strMin, strMax) => {
    return [
      { value: -20, label: strMin },
      { value: 0, label: "" },
      { value: 20, label: strMax },
    ]
  }

  const SliderComponent = ({ disabled, index, iid, jid, adv }) => {
    let strMin = "Pore"
    let strMax = "Solid"
    if (index === 0) strMax = "Micro"
    if (index === 2) strMin = "Micro"
    let isRunning =
      adjustJob?.status === STATUS_RUNNING ||
      adjustJob?.status === STATUS_WAITING
    let restrict = disabled || isRunning

    return (
      <React.Fragment>
        <div className="horizontal-slider-box">
          <Slider
            aria-labelledby="discrete-slider-always"
            defaultValue={[adv]}
            disabled={restrict}
            key={sliderResetCount}
            marks={customMarks(strMin, strMax)}
            max={20}
            min={-20}
            onChangeCommitted={(e, value) =>
              onStopSlider(value, index, iid, jid)
            }
            orientation="horizontal"
            size="big"
            step={1}
          />
        </div>
      </React.Fragment>
    )
  }

  const AdjustComponent = useCallback(
    ({ adv, iid, jid }) => {
      let localDisabled = !adjust
      return (
        <div
          className="parameters-holder"
          data-tooltip-content={tooltips.seg_p3}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
        >
          <div className="inline-holder">
            <h4 className="subtitle">
              Interactive Adjustment
              {(adjustJob?.status === STATUS_RUNNING ||
                adjustJob?.status === STATUS_WAITING) && (
                <div className="dotloading">...</div>
              )}
            </h4>
            <div className="right-holder" id="seg-tool-p3">
              <div className="checkbox-holder">
                <div className="custom-control custom-checkbox">
                  <input
                    checked={adjust}
                    className="custom-control-input"
                    disabled={job?.status !== STATUS_FINISHED}
                    id="customCheck"
                    onChange={() => {
                      if (adjust) {
                        setAdjust(false)
                      } else {
                        setAdjust(true)
                      }
                    }}
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customCheck"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="parameter">
            {localDisabled ? null : (
              <>
                <SliderComponent
                  adv={adv[0]}
                  disabled={localDisabled}
                  iid={iid}
                  index={0}
                  jid={jid}
                />
                <SliderComponent
                  adv={adv[1]}
                  disabled={localDisabled}
                  iid={iid}
                  index={1}
                  jid={jid}
                />
                <SliderComponent
                  adv={adv[2]}
                  disabled={localDisabled}
                  iid={iid}
                  index={2}
                  jid={jid}
                />
              </>
            )}
          </div>
        </div>
      )
    },
    [adjust, adjustJob, job?.status, faceImage],
  )

  let jid = undefined
  let iid = undefined
  if (job?.status === STATUS_FINISHED) {
    jid = job.jid
    iid = viewID
  }

  return (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p1}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p1"
            >
              <Col xs={PARAM_COLUMN}> Model </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    size="sm"
                    variant="info"
                  >
                    {model}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-font"
                    style={{ margin: 0 }}
                  >
                    <Dropdown.Item onClick={() => setModel("Stable_256")}>
                      {" "}
                      StableST_Old{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModel("StableST_v1")}>
                      {" "}
                      StableST_v1.0{" "}
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => setModel("DevST&CB_v1.4")}>
                      {" "}
                      DevST&CB_v1.4{" "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p2"
            >
              <Col xs={PARAM_COLUMN}> Min Norm </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"min"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={thresholdMin}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p3"
            >
              <Col xs={PARAM_COLUMN}> Max Norm </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"max"}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={thresholdMax}
                />
              </Col>
            </Row>

            <ReactTooltip anchorId="seg-tool-p1" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p2" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
          </div>
          <h4 className="subtitle"> Input Image [{INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={INPUT_TYPE}
            images={memoizedImages}
            jobType={JOB_TYPE}
            onChangeImage={onChangeImage}
            pid={memoPid}
            viewID={viewID}
          />
          <h4 className="subtitle"> Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={onChangeOutput}
              placeholder=" Output File Name"
              type="text"
              value={outputName}
            />
          </div>
        </div>
        <AdjustComponent adv={adjustValues} iid={iid} jid={jid} />
        <Progress color="success" max="100" value={job?.progress || 0}>
          {job?.progress || 0}%
        </Progress>
        <br />
        <LaunchButton
          adjust={adjust}
          jobStatus={job?.status}
          killJob={killJob}
          launchJob={launchJob}
          readyToLaunch={readyToLaunch}
        />
        <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
      </div>
      <div className="toolResultWrapper">
        <PrintImageSeg
          adjustmentImage={adjustmentImage}
          isShowAdjustmentOutputImage={
            adjust &&
            job?.status === STATUS_FINISHED &&
            adjustJob?.status === STATUS_FINISHED
          }
          job={job}
          onChangeFaceImage={(value) => setFaceImage(value)}
          pid={props.project.pid}
          selectedImage={selectedImage}
        />
      </div>
    </div>
  )
}

SegmentationTool.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(
  SegmentationTool,
)
