import { useContext } from "react"

import { NetworkContext } from "./NetworkTool.ctx"

export const useNetworkTool = () => {
  const context = useContext(NetworkContext)

  if (!context) {
    throw new Error("useNetworkTool must be used within a NetworkProvider")
  }

  return context
}
