/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef } from "react"

import { useCacheIdsOpenImage } from "./useCacheIdsOpenImage"

const MAX_DEPTH_SHOW_ALL = 5

export const useTreeOpenDefaultImage = (treeData, cb) => {
  const { cacheIds, setCacheIds } = useCacheIdsOpenImage()

  const firstRender = useRef(true)
  useEffect(() => {
    if (!firstRender.current) {
      if (Object.keys(cacheIds).length > 0) {
        const listIds = Object.keys(cacheIds).map((iid) => {
          const node = treeData.find((item) => item.iid === iid)
          return node?.id
        })
        cb && cb(listIds)
      }
      return
    }
    const size = treeData.length

    if (size <= 0) return
    const findListIdsParent = (treeData) => {
      const listIds = []
      const listNodes = []
      let depth = 0
      let flagBreakCount = 100
      while (
        listIds.length <= MAX_DEPTH_SHOW_ALL &&
        listIds.length < size &&
        flagBreakCount > 0
      ) {
        const _tree = treeData.filter((item) => item.depth === depth)
        const ids = _tree.map((item) => item.id)
        listIds.push(...ids)
        listNodes.push(..._tree)
        depth++
        flagBreakCount--
      }
      return { listIds, listNodes }
    }
    firstRender.current = false

    const { listIds, listNodes } = findListIdsParent(treeData)
    const _cacheIds = listNodes.reduce(
      (acc, cur) => ({ ...acc, [cur.iid]: true }),
      {},
    )
    setCacheIds(_cacheIds)
    cb && cb(listIds)
  }, [JSON.stringify(treeData)])

  const handleCacheIds = useCallback(
    (id, flag) => {
      if (flag) {
        setCacheIds({ ...cacheIds, [id]: true })
      } else {
        delete cacheIds[id]
        setCacheIds({ ...cacheIds })
      }
    },
    [cacheIds],
  )

  return { handleCacheIds }
}
