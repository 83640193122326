import { useState } from "react"

export const useMicroPorosity = ({ defaultValue = 0.5 }) => {
  const [microPorosity, setMicroPorosity] = useState(defaultValue)

  const updateMicroPorosity = (value) => {
    const numValue = Number(value)
    if (!isNaN(numValue)) {
      setMicroPorosity(value)
    }
  }

  return {
    microPorosity,
    updateMicroPorosity,
  }
}
