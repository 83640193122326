export class MakeFileFormLinks {
  constructor(links) {
    this.links = links
  }

  _fetchBlob(link) {
    return fetch(link).then((response) => response.blob())
  }

  _fetchBlobArray() {
    return Promise.all(this.links.map((link) => this._fetchBlob(link)))
  }

  _makeBlobToFile(blob, fileName, fileType) {
    return new File([blob], fileName, { type: fileType })
  }

  _readAsDataURL(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => resolve(reader.result)
      reader.onerror = () => reject(reader.error)
      reader.readAsDataURL(file)
    })
  }

  _makeFiles() {
    return this._fetchBlobArray().then((blobs) => {
      return Promise.all(
        blobs.map((blob, index) => {
          const fileName = this.links[index].split("/").pop()?.split("?")[0]
          const fileType = blob.type

          return this._makeBlobToFile(blob, fileName, fileType)
        }),
      )
    })
  }

  async execute() {
    const files = await this._makeFiles()

    const reducer = (dataTransfer, file) => {
      dataTransfer.items.add(file)
      return dataTransfer
    }

    return files.reduce(reducer, new DataTransfer()).files
  }
}
