import { useEffect, useMemo, useState } from "react"

import {
  ADJUSTMENT_JOB,
  CROP_JOB,
  EXTRA_JOB,
  PM_JOB,
  SEGMENTATION_JOB,
  SGAN_JOB,
  SUPERRESOLUTION_JOB,
} from "../constants/jobnames"
import { isAuth } from "../helpers/auth"

const appURL = process.env.REACT_APP_API_URL

const useGetImages = ({
  selectedImage,
  pid,
  jobname,
  isShowOutputImage = false,
}) => {
  const [_jobname, setJobname] = useState(jobname)

  const iid = selectedImage?.iid
  const imageName = selectedImage?.name
  const user = isAuth().email

  const indices = useMemo(() => {
    if (!imageName) return []
    return [
      { type: CROP_JOB, index: imageName.indexOf("crop_") },
      { type: SUPERRESOLUTION_JOB, index: imageName.indexOf("SR_") },
      { type: SEGMENTATION_JOB, index: imageName.indexOf("seg_") },
      { type: PM_JOB, index: imageName.indexOf("pm_") },
    ]
  }, [imageName])

  const getJobNameByImageName = (_indices = []) => {
    const validIndices = _indices.filter(({ index }) => index !== -1)
    const minIndexObj = validIndices.reduce(
      (min, current) => (current.index < min.index ? current : min),
      validIndices[0],
    )

    if (minIndexObj) {
      return minIndexObj.type
    }

    return null
  }

  // This effect is used to determine the jobname to print the output image.
  useEffect(() => {
    if (isShowOutputImage) {
      setJobname(jobname)
    }
  }, [isShowOutputImage, jobname])

  // This effect is used to determine the jobname based on the image name.
  // Use for tools: SegmentationTool, SuperResolutionTool
  useEffect(() => {
    if (!imageName || !jobname || isShowOutputImage) return

    switch (jobname) {
      case SEGMENTATION_JOB:
      case SUPERRESOLUTION_JOB:
      case PM_JOB:
        {
          let _jobnameTemp = getJobNameByImageName(indices) ?? EXTRA_JOB // default
          setJobname(_jobnameTemp)
        }
        break

      default:
        break
    }
  }, [jobname, imageName, indices, isShowOutputImage])

  const xyzImages = useMemo(() => {
    if (!iid || !_jobname) return null

    const baseImageURL = [appURL, "backend", user, pid, iid]
    let metaXY = []
    let metaXZ = []
    let metaYZ = []
    switch (_jobname) {
      case CROP_JOB:
      case EXTRA_JOB:
        metaXY = [EXTRA_JOB, "xyPlane.png"]
        metaXZ = [EXTRA_JOB, "xzPlane.png"]
        metaYZ = [EXTRA_JOB, "yzPlane.png"]
        break

      case SUPERRESOLUTION_JOB:
        if (isShowOutputImage) {
          metaXY = [SUPERRESOLUTION_JOB, "SR_XY.png"]
          metaXZ = [SUPERRESOLUTION_JOB, "SR_XZ.png"]
          metaYZ = [SUPERRESOLUTION_JOB, "SR_YZ.png"]
        } else {
          metaXY = [SUPERRESOLUTION_JOB, "tomo_XY.png"]
          metaXZ = [SUPERRESOLUTION_JOB, "tomo_XZ.png"]
          metaYZ = [SUPERRESOLUTION_JOB, "tomo_YZ.png"]
        }
        break

      case SEGMENTATION_JOB:
        if (isShowOutputImage) {
          metaXY = [SEGMENTATION_JOB, "seg_XY.png"]
          metaXZ = [SEGMENTATION_JOB, "seg_XZ.png"]
          metaYZ = [SEGMENTATION_JOB, "seg_YZ.png"]
        } else {
          metaXY = [SEGMENTATION_JOB, "tomo_XY.png"]
          metaXZ = [SEGMENTATION_JOB, "tomo_XZ.png"]
          metaYZ = [SEGMENTATION_JOB, "tomo_YZ.png"]
        }

        break

      case ADJUSTMENT_JOB:
        if (isShowOutputImage) {
          metaXY = [ADJUSTMENT_JOB, "seg_XY.png"]
          metaXZ = [ADJUSTMENT_JOB, "seg_XZ.png"]
          metaYZ = [ADJUSTMENT_JOB, "seg_YZ.png"]
        } else {
          metaXY = [SEGMENTATION_JOB, "tomo_XY.png"]
          metaXZ = [SEGMENTATION_JOB, "tomo_XZ.png"]
          metaYZ = [SEGMENTATION_JOB, "tomo_YZ.png"]
        }

        break

      case SGAN_JOB:
        metaXY = [SGAN_JOB, "gen_XY.png"]
        metaXZ = [SGAN_JOB, "gen_XZ.png"]
        metaYZ = [SGAN_JOB, "gen_YZ.png"]
        break

      case PM_JOB:
        if (isShowOutputImage) {
          metaXY = [PM_JOB, "xyPlane.png"]
          metaXZ = [PM_JOB, "xzPlane.png"]
          metaYZ = [PM_JOB, "yzPlane.png"]
        } else {
          metaXY = [EXTRA_JOB, "xyPlane.png"]
          metaXZ = [EXTRA_JOB, "xzPlane.png"]
          metaYZ = [EXTRA_JOB, "yzPlane.png"]
        }
        break

      default:
        break
    }

    const xyImageName = [...baseImageURL, ...metaXY].join("/")
    const xzImageName = [...baseImageURL, ...metaXZ].join("/")
    const yzImageName = [...baseImageURL, ...metaYZ].join("/")

    return {
      xyImageName,
      xzImageName,
      yzImageName,
    }
  }, [iid, _jobname, user, pid, isShowOutputImage])

  return {
    xyImageName: xyzImages?.xyImageName ?? null,
    xzImageName: xyzImages?.xzImageName ?? null,
    yzImageName: xyzImages?.yzImageName ?? null,
  }
}

export default useGetImages
