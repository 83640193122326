/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { toast } from "react-toastify"

import {
  NETWORK_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../../constants/jobnames"
import ConsoleHelper from "../../helpers/ConsoleHelper"
import { isSameSizeImage } from "../../helpers/IsSameSizeImage"
import { useMicroPorosity } from "../../hooks/screens/NetworkTool/useMicroPorosity"
import { useUpdateMicroView } from "../../hooks/screens/NetworkTool/useUpdateMicroView"
import useJobNotification from "../../hooks/useJobNotification"
import { NetworkConstants } from "./NetworkTool.constant"
import { getPorosityMapId } from "./NetworkTool.helper"

export const NetworkContext = createContext({})

export const NetworkContextProvider = ({ children, ...props }) => {
  const memoizedProject = useMemo(() => {
    return props.project
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.project)])

  const memoizedImage = useMemo(() => {
    return props.project.images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(memoizedProject.images)])

  const [viewID, setViewID] = useState(() => {
    const image = Object.values(memoizedImage).find(
      (image) =>
        image.type === NetworkConstants.INPUT_TYPE &&
        image.createByTool !== NetworkConstants.INPUT_PM_TYPE,
    )
    if (!image) return ""

    return image.iid
  })

  const selectedImage = useMemo(() => {
    return memoizedImage[viewID]
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoizedImage, viewID])

  const [porosityMapNameID, setPorosityMapNameID] = useState(() => {
    const jobs = selectedImage?.jobs[NETWORK_JOB]
    if (jobs === undefined) return ""
    // inputs.Parameters.porosityMapName
    const porosityMapName = jobs.parameters?.Parameters?.porosityMapName || ""
    return getPorosityMapId(porosityMapName)
  })

  const [job, setJob] = useState(() => {
    let _job = { status: STATUS_UNKNOWN, progress: 0 }
    if (
      selectedImage?.jobs !== undefined &&
      selectedImage?.jobs[NETWORK_JOB] !== undefined
    ) {
      _job = selectedImage?.jobs[NETWORK_JOB]
    }
    return _job
  })
  const intervalId = useRef(null)

  const memoizedPid = useMemo(() => {
    return props.project?.pid || null
  }, [props.project?.pid])

  const readyToLaunch = useMemo(() => {
    if (
      selectedImage != null &&
      job.status !== STATUS_RUNNING &&
      job.status !== STATUS_WAITING
    ) {
      return true
    }
    return false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedImage, JSON.stringify(job)])

  const outputName = useMemo(() => {
    if (selectedImage === null) return ""
    return NetworkConstants.NAME_PREFIX + selectedImage?.name
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(selectedImage ?? {})])

  const extraJob = selectedImage?.jobs?.[NETWORK_JOB]

  const { microPorosity, updateMicroPorosity } = useMicroPorosity({
    defaultValue: 0.5,
  })
  useUpdateMicroView({
    viewId: viewID,
    params: extraJob?.parameters,
    updateMicroPorosity,
  })

  const sendUpdateRequest = useCallback(
    (iid, jid) => {
      if (viewID !== "" && job.jid !== undefined) {
        const payload = {
          pid: props.project.pid,
          iid,
          jid,
          jobname: NETWORK_JOB,
        }
        props.getJobStatus(payload)
      }
    },
    [job.jid, props, viewID],
  )

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      setViewID(index)
      const image = memoizedImage[index]
      if (image.jobs !== undefined && image.jobs[NETWORK_JOB] !== undefined) {
        const jobs = image.jobs[NETWORK_JOB]
        setJob(jobs)
        const porosityMapName =
          jobs.parameters?.Parameters?.porosityMapName || ""
        const porosityMapId = getPorosityMapId(porosityMapName)

        setPorosityMapNameID(porosityMapId || "")
        return
      }
      setJob({ status: STATUS_UNKNOWN, progress: 0 })
      setPorosityMapNameID("")
      return
    },
    [memoizedImage],
  )

  useEffect(() => {
    props.getProject(props.project.pid)
    return () => clearInterval(intervalId.current)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let image = props.project.images[viewID]
    if (
      image !== undefined &&
      image.jobs !== undefined &&
      image.jobs[NETWORK_JOB] !== undefined
    ) {
      let jobImage = image.jobs[NETWORK_JOB]
      if (
        jobImage.status !== job.status ||
        jobImage.progress !== job.progress ||
        jobImage.results !== job.results
      ) {
        if (
          (jobImage.status !== STATUS_RUNNING &&
            jobImage.status !== STATUS_WAITING) ||
          jobImage.jid === undefined
        ) {
          clearInterval(intervalId.current)
        }
        if (
          job.status !== STATUS_FINISHED &&
          jobImage.status === STATUS_FINISHED
        ) {
          props.getProject(props.project.pid)
        }
        setJob(jobImage)
      }
    }
  }, [viewID, job, props])

  useJobNotification({ job })

  const pollApi = useCallback(() => {
    // ConsoleHelper("JobStatus Polling ", this.state.job)
    if (Object.keys(job).length) {
      if (
        !(
          (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
          job.jid === undefined
        )
      ) {
        sendUpdateRequest(viewID, job.jid)
      }
    }
  }, [job, sendUpdateRequest, viewID])

  useEffect(() => {
    if (job.status === STATUS_WAITING || job.status === STATUS_RUNNING) {
      intervalId.current = setInterval(pollApi, NetworkConstants.POLLING_TIME)
    }
    return () => clearInterval(intervalId.current)
  }, [job, pollApi])

  const onChange = useCallback(
    (e) => {
      let number = Number(e.target.value)
      if (isNaN(number)) {
        number = 0
      }
      number = Math.min(Math.max(number, 0.0), 1.0)

      if (e.target.id === "microPorosity") {
        updateMicroPorosity(number)
      }
    },
    [updateMicroPorosity],
  )

  const onChangeImage = useCallback(
    (value) => {
      changeView(value)
    },
    [changeView],
  )

  const onChangePorosityMapNameImage = useCallback(
    (iid) => {
      if (!viewID) {
        toast.error("Please select an image network tool first.")
        return
      }
      const imageView = memoizedImage[viewID]
      const imagePorosityMap = memoizedImage[iid]

      if (!isSameSizeImage(imageView, imagePorosityMap)) {
        toast.error(
          "Please select an image with the same size as the network tool image.",
        )
        return
      }

      setPorosityMapNameID((old) => {
        if (old === iid) return ""
        return iid
      })
    },
    [memoizedImage, viewID],
  )

  const launchJob = useCallback(() => {
    ConsoleHelper("Launching Job")
    let properties = {
      out_path: outputName,
      microPorosity: Number(microPorosity),
      porosityMapName: porosityMapNameID,
    }
    let payload = {
      pid: props.project.pid,
      iid: selectedImage.iid,
      jobname: NETWORK_JOB,
      data: properties,
    }
    props.doJob(payload)
  }, [microPorosity, outputName, porosityMapNameID, props, selectedImage])

  const killJob = useCallback(() => {
    clearInterval(intervalId.current)
    if (job.jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        iid: viewID,
        jid: job.jid,
        jobname: NETWORK_JOB,
      }
      ConsoleHelper("Killing Job: " + JSON.stringify(payload))
      props.killJob(payload)
    }
  }, [job.jid, props, viewID])

  const onChangeNotPM = useCallback((node) => {
    return node.createByTool !== NetworkConstants.INPUT_PM_TYPE
  }, [])

  const jobResult = useMemo(() => {
    if (job.results === undefined) return null
    return job.results
  }, [job.results])

  const jobString = useMemo(() => JSON.stringify(job), [job])

  const networkContextValue = useMemo(() => {
    return {
      job: JSON.parse(jobString),
      jobString,
      memoizedPid,
      readyToLaunch,
      jobResult,
      memoizedImage,
      microPorosity,
      porosityMapNameID,
      viewID,
      project: memoizedProject,
      killJob,
      launchJob,
      onChange,
      onChangeImage,
      onChangeNotPM,
      onChangePorosityMapNameImage,
    }
  }, [
    memoizedProject,
    jobString,
    memoizedPid,
    memoizedImage,
    readyToLaunch,
    jobResult,
    memoizedProject,
    microPorosity,
    porosityMapNameID,
    viewID,
    killJob,
    launchJob,
    onChange,
    onChangeImage,
    onChangeNotPM,
    onChangePorosityMapNameImage,
  ])
  return (
    <NetworkContext.Provider value={networkContextValue}>
      {children}
    </NetworkContext.Provider>
  )
}
