import React from "react"

export const useCacheIdsOpenImage = () => {
  const [cacheIds, setCacheIds] = React.useState({})

  return {
    cacheIds,
    setCacheIds,
  }
}
