import Slider from "@mui/material/Slider"
import React from 'react'

import useDebounceFn from "../../hooks/useDebounceFn"

const DEFAULT_STATES = {
  min: 0,
  max: 100,
  brightness: 1,
  contrast: 1
}

const SLIDER_MARKS = [
  { value: 0, label: "" },
  { value: 100, label: "" },
]

const calculateBrightnessAndContrast = (min, max) => {
  let brightness = (min + max) / 100
  if (max > 100) {
    brightness = (min + 100) / 100 + ((max - 100) / 100) * 5
  }
  
  const contrast = max - min < 100 ? 1 / ((max - min) / 100) : 1

  return { brightness, contrast }
}

const useSliderState = (onChange) => {
  return useDebounceFn((e, [min, max]) => {
    if (!onChange) return

    const { brightness, contrast } = calculateBrightnessAndContrast(min, max)
    onChange({ min, max, brightness, contrast })
  }, 300)
}

function VerticalSlider({
  onChange,
  defaultStates = DEFAULT_STATES,
  useDebounced = true,
}) {
  const debouncedFn = useSliderState(onChange)

  const handleSliderChange = (e, [min, max]) => {
    const { brightness, contrast } = calculateBrightnessAndContrast(min, max)
    onChange?.({ min, max, brightness, contrast })
  }

  return (
    <Slider
      aria-labelledby="discrete-slider-always"
      defaultValue={[defaultStates.min, defaultStates.max]}
      key={`${defaultStates.min}-${defaultStates.max}`}
      marks={SLIDER_MARKS}
      max={200}
      min={0}
      onChangeCommitted={useDebounced ? debouncedFn : handleSliderChange}
      orientation="vertical"
      size="small"
      step={1}
    />
  )
}

export default VerticalSlider