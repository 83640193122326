import { useEffect, useMemo, useRef, useState, useTransition } from "react"

import { EXTRA_JOB, STATUS_FINISHED } from "../../../constants/jobnames"
import useDebounceFn from "../../../hooks/useDebounceFn"
import useGetImages from "../../../hooks/useGetJobImages"

const usePrintImageIO = ({
  disableButtons,
  disableSlider,
  selectedImage,
  pid,
  job,
}) => {
  const [isPending, startTransition] = useTransition()
  const [disabled, setDisabled] = useState(true)
  const { xyImageName, yzImageName, xzImageName } = useGetImages({
    selectedImage,
    pid,
    jobname: EXTRA_JOB,
  })
  const transWrapperRef = useRef(null)
  const [cutPlane, setCutPlane] = useState({ pos: 0, face: "xy" })
  const [zOption, setZOption] = useState({ pX: 0, pY: 0, sC: 1 })
  const [sliderState, setSliderState] = useState({
    brightness: 1,
    contrast: 1,
  })

  const onZoomUpdate = useDebounceFn((res) => {
    if (res.state) {
      setZOption({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale,
      })
    }
  }, 100)

  const onPanUpdate = useDebounceFn((res) => {
    if (res.state) {
      setZOption({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale,
      })
    }
  }, 500)

  const zoomInImage = () => {
    if (isPending) return
    startTransition(() => {
      setZOption((curr) => {
        return { ...curr, sC: curr.sC + 0.2 }
      })
    })
  }

  const zoomOutImage = () => {
    if (isPending) return
    setZOption((curr) => ({ ...curr, sC: curr.sC - 0.2 }))
  }

  const resetImage = () => {
    if (isPending) return
    setZOption({ pX: 0, pY: 0, sC: 1 })
  }

  const handleUpdateSliderState = (value) => {
    if (!value) return
    const _temp = { ...sliderState }
    if (value.brightness !== undefined) _temp.brightness = value.brightness
    if (value.contrast !== undefined) _temp.contrast = value.contrast
    setSliderState(_temp)
  }

  const updateCutPlane = (value) => {
    if (!value) return
    setCutPlane(value)
  }

  useEffect(() => {
    if (!job) {
      if (!disabled) setDisabled(true)

      return
    }

    if (job?.status === STATUS_FINISHED) {
      if (disabled) setDisabled(false)
    }
  }, [disabled, job, job?.status])

  useEffect(() => {
    if (!transWrapperRef.current || !zOption || !Object.keys(zOption).length)
      return
    transWrapperRef.current.setTransform(zOption.pX, zOption.pY, zOption.sC)
  }, [zOption])

  const imageName = useMemo(() => {
    if (disabled) return xyImageName
    const nameImages = [xyImageName, xzImageName, yzImageName]
    return nameImages?.[cutPlane.pos] || nameImages[0]
  }, [cutPlane.pos, disabled, xyImageName, xzImageName, yzImageName])

  return {
    job,
    transWrapperRef,
    disabled,
    sliderState,
    cutPlane,
    imageName,
    disableButtons,
    disableSlider,
    onZoomUpdate,
    onPanUpdate,
    resetImage,
    zoomInImage,
    zoomOutImage,
    handleUpdateSliderState,
    updateCutPlane,
  }
}

export default usePrintImageIO
