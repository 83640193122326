import React from "react"

import { STATUS_FINISHED } from "../../../constants/jobnames"
import ImageWrapper from "../ImageWrapper/ImageWrapper.view"
import {
  PrintImageSegProvider,
  usePrintImageSegContext,
} from "./PrintImageSeg.context"
import PrintImageSegItem from "./PrintImageSegItem/PrintImageSegItem.view"

const PrintImageSeg = ({
  isShowAdjustmentOutputImage,
  adjustmentImage,
  job,
  pid,
  selectedImage,
  onChangeFaceImage,
}) => {
  const { cutPlane } = usePrintImageSegContext()

  const face = cutPlane.face

  const isSameAdjustSameFace =
    adjustmentImage?.jobs?.adjustment?.parameters?.face === face ||
    adjustmentImage?.jobs?.adjustment?.[face]?.parameters?.face === face

  return (
    <div className="images-container">
      <ImageWrapper job={job} title="Input">
        <PrintImageSegItem
          isShowOutputImage={false}
          job={selectedImage?.jobs?.extra}
          onChangeFaceImage={onChangeFaceImage}
          pid={pid}
          selectedImage={selectedImage}
        />
      </ImageWrapper>

      <ImageWrapper job={job} title="Output">
        {job?.status === STATUS_FINISHED ? (
          <PrintImageSegItem
            adjustmentImage={adjustmentImage}
            isShowAdjustmentOutputImage={
              isSameAdjustSameFace && isShowAdjustmentOutputImage
            }
            isShowOutputImage={true}
            job={job}
            onChangeFaceImage={onChangeFaceImage}
            pid={pid}
            selectedImage={selectedImage}
          />
        ) : (
          <PrintImageSegItem />
        )}
      </ImageWrapper>
    </div>
  )
}

const PrintImageSegContainer = (props) => {
  return (
    <PrintImageSegProvider>
      <PrintImageSeg {...props} />
    </PrintImageSegProvider>
  )
}

export default PrintImageSegContainer
