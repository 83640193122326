import "./Parameters.css"

import React from "react"

import { ExpandableList } from "../ExpandableList"

const SIZE_DEFAULT = 0
const CropParameters = ({
  image = null,
  parameter,
  onChange,
  openAdvancedDefault,
}) => {
  const crop = parameter

  const calc = (val) => {
    return Math.round((val * 1e12) / 1e12)
  }

  return (
    <ExpandableList
      ariaLabel="advanced crop options"
      label={"Advanced Options"}
      list={[
        {
          label: "Origin X",
          value: calc((crop.x / 100) * (image?.nx || SIZE_DEFAULT)),
          // value: Math.ceil((crop.x / 100)),
          min: 0,
          max: 100,
          onChange: (x) =>
            onChange({ ...crop, x: (+x / (image?.nx || SIZE_DEFAULT)) * 100 }),
          // onChange: (x) => onChange({ ...crop, x : (x / (image?.nx || SIZE_DEFAULT)) * 100 })
        },
        {
          label: "Origin Y",
          value: calc((crop.y / 100) * (image?.nx || SIZE_DEFAULT)),
          min: 0,
          max: 100 * (image?.ny || SIZE_DEFAULT),
          onChange: (y) =>
            onChange({ ...crop, y: (+y / (image?.ny || SIZE_DEFAULT)) * 100 }),
        },
        {
          label: "Origin Z",
          value: calc((crop.z / 100) * (image?.nz || SIZE_DEFAULT)),
          min: 0,
          max: 100 * (image?.nz || SIZE_DEFAULT),
          onChange: (z) =>
            onChange({ ...crop, z: (+z / (image?.nz || SIZE_DEFAULT)) * 100 }),
        },
        {
          label: `Crop Size X`,
          value: calc(image?.nx || SIZE_DEFAULT) * (crop.cx / 100),
          min: 0,
          max: 100 * (image?.nx || SIZE_DEFAULT),
          onChange: (cx) =>
            onChange({
              ...crop,
              cx: (+cx / (image?.nx || SIZE_DEFAULT)) * 100,
            }),
        },
        {
          label: `Crop Size Y`,
          value: calc(image?.ny || SIZE_DEFAULT) * (crop.cy / 100),
          min: 0,
          max: 100 * (image?.ny || SIZE_DEFAULT),
          onChange: (cy) =>
            onChange({
              ...crop,
              cy: (+cy / (image?.ny || SIZE_DEFAULT)) * 100,
            }),
        },
        {
          label: `Crop Size Z`,
          value: calc((image?.nz || SIZE_DEFAULT) * (crop.cz / 100)),
          min: 0,
          max: 100 * (image?.nz || SIZE_DEFAULT),
          onChange: (cz) =>
            onChange({
              ...crop,
              cz: (+cz / (image?.nz || SIZE_DEFAULT)) * 100,
            }),
        },
      ]}
      openDefault={openAdvancedDefault}
    />
  )
}

export default CropParameters
