import { useState } from "react"

const useClayFolder = () => {
  const [toggle, setToggle] = useState(false)

  const handleToggle = () => {
    setToggle(!toggle)
  }

  return {
    handleToggle,
    toggle,
  }
}

export default useClayFolder
