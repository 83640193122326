import PropTypes from "prop-types"
import React, { useCallback, useState } from "react"

import SuperResolutionParameter from "../components/Parameters/SupperResolutionParameter"
import { SUPERRESOLUTION_JOB } from "../constants/jobnames"
import GenericTool from "./GenericTool"

const modelDefault = "Stable_v1.0"
const scaleDefault = 2
function SuperResolutionTool(props) {
  const [model, setModel] = useState(modelDefault)
  const [scale, setScale] = useState(scaleDefault)

  const getLaunchProps = useCallback(() => {
    return {
      scale: scale,
      model: model,
    }
  }, [scale, model])

  const onResetParameter = useCallback((_parameter) => {
    const modeMapping = {
      "fSR_v1.0": "Stable_v1.0",
      "fSR_v1.2": "Dev_v1.2",
    }

    let _scale = _parameter?.scale || scaleDefault
    let _model = modeMapping[_parameter?.sr_model] || modelDefault

    setScale(_scale)
    setModel(_model)
  }, [])

  const handleUpdateParameter = (newParameter) => {
    if (newParameter.model) return setModel(newParameter.model)
    if (newParameter.scale) return setScale(newParameter.scale)
  }

  return (
    <GenericTool
      getLaunchProps={getLaunchProps}
      jobType={SUPERRESOLUTION_JOB}
      onResetParameter={onResetParameter}
      prefix="SR_"
      project={props.project}
    >
      <SuperResolutionParameter
        onChange={handleUpdateParameter}
        parameter={{ model, scale }}
      />
    </GenericTool>
  )
}

SuperResolutionTool.propTypes = {
  project: PropTypes.object.isRequired,
}

export default SuperResolutionTool
