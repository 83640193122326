import { useMemo, useState } from "react"

import { CROP_JOB } from "../../../constants/jobnames"
import useGetImages from "../../../hooks/useGetJobImages"
import { getCValue, getPlanesOrder } from "../../../utils/images"

const usePrintImageCrop = ({
  crop,
  disableSlider,
  selectedImage,
  pid,
  onChange = () => {},
}) => {
  const { xyImageName, yzImageName, xzImageName } = useGetImages({
    selectedImage,
    pid,
    jobname: CROP_JOB,
  })
  const [imagePlane, setImagePlane] = useState(0)
  const [sliderState, setSliderState] = useState({
    brightness: 1,
    contrast: 1,
  })
  const getCropValuesByPlane = () => {
    const croppingPlanes = getPlanesOrder(imagePlane)
    return {
      x: crop[croppingPlanes[0]],
      y: crop[croppingPlanes[1]],
      width: crop[getCValue(croppingPlanes[0])],
      height: crop[getCValue(croppingPlanes[1])],
      unit: "%",
    }
  }

  const handleUpdateSliderState = (value) => {
    if (!value) return
    const _temp = { ...sliderState }
    if (value.brightness !== undefined) _temp.brightness = value.brightness
    if (value.contrast !== undefined) _temp.contrast = value.contrast
    if (value.min !== undefined) _temp.min = value.min
    if (value.max !== undefined) _temp.max = value.max
    setSliderState(_temp)
  }

  const handleSetCrop = (c) => {
    const newState = { ...crop }
    const croppingPlanes = getPlanesOrder(imagePlane)
    newState[croppingPlanes[0]] = c.x
    newState[getCValue(croppingPlanes[0])] = c.width
    newState[croppingPlanes[1]] = c.y
    newState[getCValue(croppingPlanes[1])] = c.height
    if (crop[getCValue(croppingPlanes[2])] === 0) {
      newState[getCValue(croppingPlanes[2])] = 100
    }

    if (onChange) onChange(newState)
  }

  const imageName = useMemo(() => {
    const nameImages = [xyImageName, xzImageName, yzImageName]
    return nameImages?.[imagePlane] || nameImages[0]
  }, [imagePlane, xyImageName, xzImageName, yzImageName])

  return {
    crop,
    imagePlane,
    imageName,
    sliderState,
    disableSlider,
    handleUpdateSliderState,
    setImagePlane,
    handleSetCrop,
    getCropValuesByPlane,
  }
}

export default usePrintImageCrop
