import { useState } from "react"
import { useDispatch } from "react-redux"

import { uploadRockType } from "../../actions/projectSlice"

export const useAddRockType = ({ project }) => {
  const dispatch = useDispatch()
  const [fileRockType, setFileRockType] = useState(null)
  const [rockTypeLabel, setRockTypeLabel] = useState(1)
  const [rockTypeName, setRockTypeName] = useState("")

  const onChangeFile = (event) => {
    let files = event.currentTarget.files
    if (files.length > 0) {
      setFileRockType(files[0])
      if (!rockTypeName.trim()) {
        setRockTypeName(files[0].name)
      }
    }
  }

  const onChangeLabel = (event) => {
    setRockTypeLabel(event.target.value)
  }

  const onChangeRockTypeName = (event) => {
    setRockTypeName(event.target.value)
  }

  const handleUploadRockType = () => {
    if (!fileRockType) {
      alert("Please select a file to upload.")
      return
    }
    if (!rockTypeName.trim()) {
      alert("Please enter a name for the RockType.")
      return
    }
    const formData = new FormData()

    formData.append("file", fileRockType)
    formData.append("label", rockTypeLabel)
    formData.append("pid", project.pid)
    formData.append("name", rockTypeName)

    dispatch(uploadRockType({ data: formData, pid: project.pid }))

    setFileRockType(null)
    setRockTypeName("")
    setRockTypeLabel(1)
  }

  return {
    fileRockType,
    onChangeFile,
    rockTypeLabel,
    onChangeLabel,
    handleUploadRockType,
    rockTypeName,
    onChangeRockTypeName,
  }
}
