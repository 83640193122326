/* eslint-disable max-len */
export const tooltips = {
  delay_show_timeout: 1000,
  sr_p1:
    // eslint-disable-next-line max-len
    "Type of model used in the SR. Stable models will be more conservative, while Dev will be more aggresive into creating new structures",
  sr_p2:
    // eslint-disable-next-line max-len
    "This is the scale of the output image. x1 will keep size and improve image quality, x2, x3 … will multiply each dimension",
  seg_p1:
    // eslint-disable-next-line max-len
    "Type of model used in the Segmentation. Stable models will be more conservative, while Dev will be more aggresive. ST mainly trained with Sandstone, CB mainly trained with Carbonates",
  seg_p2: "Top and bottom cutoff for image normalization",
  seg_p3:
    "This allows to have a fine control of the boundaries between different segmented phases",
  netsim_p1: "Run Primary Drainage invasion sequence (Mandatory)",
  netsim_p2: "Run Imbibition invasion sequence",
  netsim_p3:
    "Injection direction. XYZ represent and invation from all directions (virtual MICP)",
  netsim_p4: "Water saturation to stop the Primary Drainage sequence",
  netsim_p5: "Intefacial Tension",
  netsim_p6: "Maximum Capillary Pressure",
  slicegan_model:
    "2D Dev_v1.0: GAN model training with your 2D images \n 2D-Diff_v1.0: Diffusion based model training with your 2D images",
  slicegan_image_type: "Images can be gray scale or segmented",
  slicegan_interpolate:
    "Downscaling factor. 2D images are normally really high resolution. [0.2 < size < 1]",
  slicegan_threshold:
    "Simple or multiple thresholds (separated by a comma) to segment gray scale image. For example [20, 130, 200] (1 < threshold < 255]",
  slicegan_cell_size:
    "Physical size of the pixel in the input images. [0.001 < size < 1000]",
  slicegan_output_size:
    "Size of the output image. For example [200, 130, 250] [100 < size < 1200]",
  slicegan_segmentation:
    "Perform a segmentation of the input images using thresholds",
  slicegan_kernel_size: "Size of the kernel used for generation. [64, 128]",
  slicegan_epochs: "Number of epochs for the training. [50 < epochs < 1000]",
  slicegan_gray_threshold:
    "Top and bottom cutoff (volumetric) for gray distribution normalization",
  status_failed: "Failed",
  status_finished: "Finished",
  status_killed: "Killed",
  status_running: "Running",
  status_waiting: "Waiting",
}
