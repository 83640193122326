import { useEffect, useMemo, useRef, useState } from "react"

import { PM_JOB, STATUS_FINISHED } from "../../../../constants/jobnames"
import useGetImages from "../../../../hooks/useGetJobImages"
import { usePrintImagePMContext } from "../PrintImagePM.context"

const usePrintImageSRItem = ({
  disableButtons,
  disableSlider,
  selectedImage,
  pid,
  job,
  isShowOutputImage,
}) => {
  const {
    cutPlane,
    zOption,
    sliderState,
    onZoomUpdate,
    onPanUpdate,
    zoomInImage,
    zoomOutImage,
    resetImage,
    handleUpdateSliderState,
    updateCutPlane,
  } = usePrintImagePMContext()

  const [disabled, setDisabled] = useState(true)
  const { xyImageName, yzImageName, xzImageName } = useGetImages({
    selectedImage,
    pid,
    jobname: PM_JOB,
    isShowOutputImage,
  })
  const transWrapperRef = useRef(null)

  useEffect(() => {
    if (!job) {
      if (!disabled) setDisabled(true)

      return
    }

    if (job?.status === STATUS_FINISHED) {
      if (disabled) setDisabled(false)
    }
  }, [disabled, job, job?.status])

  useEffect(() => {
    if (!transWrapperRef.current || !zOption || !Object.keys(zOption).length)
      return
    transWrapperRef.current.setTransform(zOption.pX, zOption.pY, zOption.sC)
  }, [zOption])

  const imageName = useMemo(() => {
    if (disabled) return xyImageName
    const nameImages = [xyImageName, xzImageName, yzImageName]
    return nameImages?.[cutPlane.pos] || nameImages[0]
  }, [cutPlane.pos, disabled, xyImageName, xzImageName, yzImageName])

  return {
    transWrapperRef,
    disabled,
    onZoomUpdate,
    onPanUpdate,
    sliderState,
    cutPlane,
    imageName,
    disableButtons,
    disableSlider,
    resetImage,
    zoomInImage,
    zoomOutImage,
    handleUpdateSliderState,
    updateCutPlane,
    isShowOutputImage,
  }
}

export default usePrintImageSRItem
