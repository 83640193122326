import { IMAGE_DEFAULT } from "../constants/CommonConstants"
import { EXTRA_JOB, PM_JOB, SUPERRESOLUTION_JOB } from "../constants/jobnames"
import { isAuth } from "../helpers/auth"

export const planeMap = {
  0: "xy",
  1: "xz",
  2: "yz",
}

export const getServerImageSource = (projectId, image, plane) => {
  const user = isAuth().email

  // eslint-disable-next-line max-len
  return `${process.env.REACT_APP_API_URL}/backend/${user}/${projectId}/${image?.iid}/${EXTRA_JOB}/${planeMap[plane]}Plane.png`
}

export const getPlanesOrder = (imagePlane) => {
  let orderedPlane = ["x", "y", "z"]
  if (imagePlane === 1) {
    orderedPlane = ["x", "z", "y"]
  }
  if (imagePlane === 2) {
    orderedPlane = ["y", "z", "x"]
  }
  return orderedPlane
}

export const getCValue = (planeSymbol) => `c${planeSymbol}`

export const getCropData = (image, crop) => ({
  x: Math.floor(image.nx * (crop.x / 100)),
  y: Math.floor(image.ny * (crop.y / 100)),
  z: Math.floor(image.nz * (crop.z / 100)),
  cx: Math.ceil(image.nx * (crop.cx / 100)),
  cy: Math.ceil(image.ny * (crop.cy / 100)),
  cz: Math.ceil(image.nz * (crop.cz / 100)),
})

export const getDefaultCropValues = (image) => {
  function calcPercentage(fullLength, targetLength) {
    return (targetLength * 100) / fullLength
  }

  const sizeDefaultPercent = 80

  const cValues = {
    cx:
      image.nx < 500
        ? sizeDefaultPercent
        : Math.floor(calcPercentage(image.nx, 500)),
    cy:
      image.ny < 500
        ? sizeDefaultPercent
        : Math.floor(calcPercentage(image.ny, 500)),
    cz: image.nz < 500 ? 100 : Math.floor(calcPercentage(image.nz, 500)),
  }

  return {
    ...cValues,
    x:
      image.nx < 500
        ? (100 - sizeDefaultPercent) / 2
        : Math.floor((100 - cValues.cx) / 2),
    y:
      image.ny < 500
        ? (100 - sizeDefaultPercent) / 2
        : Math.floor((100 - cValues.cy) / 2),
    z: image.nz < 500 ? 0 : Math.floor((100 - cValues.cz) / 2),
  }
}

export const getDefaultNetexValues = (_image) => {
  return {
    microPorosity: 0.2,
  }
}

export const getImageJob = ({ pid, iid, jid, jobname }) => {
  const user = isAuth().email

  const isValidJob = [EXTRA_JOB, PM_JOB, SUPERRESOLUTION_JOB].includes(jobname)

  if (!pid || !iid || !jid || !isValidJob) return IMAGE_DEFAULT

  // /backend/v2/:user/:pid/:iid/:jobname/:imagename/:jid?

  const BASE_URL = process.env.REACT_APP_API_URL
  return `${BASE_URL}/backend/${user}/${pid}/${iid}/${jobname}/${jid}`
}

// export const getImageURL = ({ pid, image, jobname, imagename }) => {
//   let xyImageName = []
//   let xzImageName = []
//   let yzImageName = []

//   const appURL = process.env.REACT_APP_API_URL
//   const user = isAuth().email

//   xyImageName = [appURL, "backend", user, pid, iid, jobname, imagename, jid ]

//   xyImageName.join('/')
//   xzImageName.join('/')
//   yzImageName.join('/')
//   return {
//     xyImageName,
//     xzImageName,
//     yzImageName
//   }
// }
