import { useEffect, useRef } from "react"
import { toast } from "react-toastify"

import {
  STATUS_FAILED,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_WAITING,
} from "../constants/jobnames"

const useJobNotification = ({ job }) => {
  const ref_preStatusJob = useRef(null)
  const ref_preIdJob = useRef(null)

  useEffect(() => {
    ref_preIdJob.current = job?.jid || null
    ref_preStatusJob.current = null
  }, [job?.jid])

  useEffect(() => {
    if (job?.jid && ref_preIdJob.current === job?.jid) {
      if ([STATUS_WAITING, STATUS_RUNNING].includes(ref_preStatusJob.current)) {
        switch (job?.status) {
          case STATUS_FINISHED:
            toast.success("Job Finished")
            break

          case STATUS_FAILED:
            toast.error("Job Failed")
            break
          default:
            break
        }
      }

      ref_preStatusJob.current = job?.status
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job?.status])
}

export default useJobNotification
