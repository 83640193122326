import React from "react"
import { Button, Col, Row } from "react-bootstrap"

import { useAddRockType } from "./AddRockType.hook"
const AddRockTypeView = ({ project }) => {
  const {
    fileRockType,
    onChangeFile,
    rockTypeLabel,
    onChangeLabel,
    handleUploadRockType,
    rockTypeName,
    onChangeRockTypeName,
  } = useAddRockType({ project })

  return (
    <div className="parameters-holder add-rock-type">
      <h4 className="subtitle">Add RockType</h4>
      <div className="input-upload-files add-rock-type">
        <input
          className="form-control"
          hidden
          id="rockTypeFile"
          onChange={onChangeFile}
          type="file"
        />
        <label className="form-control" htmlFor="rockTypeFile">
          {fileRockType ? fileRockType.name : "Drag and Drop It Here"}
        </label>
      </div>

      <div className="w-100">
        <Row
          className="row-parameter"
          id="netsim-tool-rock-type"
          style={{ width: "100%" }}
        >
          <Col xs={8}> Index </Col>

          <Col>
            <input
              className="number-parameter2"
              max={16}
              min={1}
              onChange={onChangeLabel}
              onFocus={(e) => {
                e.target.select()
              }}
              step={1}
              type="number"
              value={rockTypeLabel}
            />
          </Col>
        </Row>

        <Row
          className="row-parameter"
          id="netsim-tool-rock-type"
          style={{ width: "100%" }}
        >
          <Col xs={8}> Name </Col>
          <Col>
            <input
              className="number-parameter2"
              name="name"
              onChange={onChangeRockTypeName}
              onFocus={(e) => {
                e.target.select()
              }}
              placeholder="Name"
              type="text"
              value={rockTypeName}
            />
          </Col>
        </Row>
        <Button
          className="btn btn-success btn-block"
          onClick={handleUploadRockType}
          style={{ marginTop: 12 }}
          type="button"
        >
          Upload
        </Button>
      </div>
    </div>
  )
}

export default AddRockTypeView
