import React, { createContext, useContext, useState } from "react"

const PrintImageSRContext = createContext(null)
import useDebounceFn from "../../../hooks/useDebounceFn"

export const PrintImageSRProvider = ({ children }) => {
  const [cutPlane, setCutPlane] = useState({ pos: 0, face: "xy" })
  const [zOption, setZOption] = useState({ pX: 0, pY: 0, sC: 1 })
  const [sliderState, setSliderState] = useState({
    input: {
      brightness: 1,
      contrast: 1,
    },
    output: {
      brightness: 1,
      contrast: 1,
    },
  })

  const onZoomUpdate = useDebounceFn((res) => {
    if (res.state) {
      setZOption({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale,
      })
    }
  }, 300)

  const onPanUpdate = useDebounceFn((res) => {
    if (res.state) {
      setZOption({
        pX: res.state.positionX,
        pY: res.state.positionY,
        sC: res.state.scale,
      })
    }
  }, 300)

  const zoomInImage = () => {
    setZOption((curr) => ({ ...curr, sC: zOption.sC + 0.2 }))
  }

  const zoomOutImage = () => {
    setZOption((curr) => ({ ...curr, sC: zOption.sC - 0.2 }))
  }

  const resetImage = () => {
    setZOption({ pX: 0, pY: 0, sC: 1 })
  }

  const handleUpdateSliderState = (value, type = "input") => {
    if (!value) return
    setSliderState((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        brightness:
          value.brightness !== undefined
            ? value.brightness
            : prev[type].brightness,
        contrast:
          value.contrast !== undefined ? value.contrast : prev[type].contrast,
      },
    }))
  }

  const updateCutPlane = (value) => {
    if (!value) return
    setCutPlane(value)
  }

  const value = {
    cutPlane,
    zOption,
    sliderState,
    onZoomUpdate,
    onPanUpdate,
    zoomInImage,
    zoomOutImage,
    resetImage,
    handleUpdateSliderState,
    updateCutPlane,
  }

  return (
    <PrintImageSRContext.Provider value={value}>
      {children}
    </PrintImageSRContext.Provider>
  )
}

export const usePrintImageSRContext = () => {
  const ctx = useContext(PrintImageSRContext)
  if (!ctx) {
    throw new Error(
      "usePrintImageSRContext must be used within a PrintImageSRProvider",
    )
  }

  return { ...ctx }
}
