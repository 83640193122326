import React, { memo } from "react"
import { Button } from "react-bootstrap"
import { Edit } from "react-feather"

const PrintDetails = ({
  image,
  name,
  edit,
  onChangeEdit,
  onChangeName,
  onChangeSize,
  onChangeResolution,
  onChangeComment,
  nx,
  ny,
  nz,
  cellSize,
  comment,
}) => {
  if (image !== undefined) {
    let sizeGB = "-"
    if (image.size !== undefined)
      sizeGB = parseFloat(Number(image.size) / Number(1e9)).toFixed(2)
    let bigEndian = false
    if (image.bigEndian !== undefined) bigEndian = Boolean(image.bigEndian)
    return (
      <div className="parameters-holder">
        <div className="inline-holder">
          <h4 className="subtitle"> Image Details </h4>
          <div className="right-holder">
            <Edit
              active={edit ? "t" : "f"}
              className="icon focus-red"
              onClick={onChangeEdit}
              size={18}
            />
          </div>
        </div>
        <div className="image-details-holder">
          <div className="inline-holder">
            <input
              className="text"
              disabled={!edit}
              onChange={onChangeName}
              onFocus={(e) => {
                e.target.select()
              }}
              type="text"
              value={name}
            />
          </div>

          <div
            className="inline-holder"
            style={{ flexWrap: "wrap", gap: "6px" }}
          >
            <div>
              <label className="label"> X </label>
              <input
                className="number"
                disabled={!edit}
                id={0}
                onChange={onChangeSize}
                onFocus={(e) => {
                  e.target.select()
                }}
                pattern="[0-9]"
                type="number"
                value={nx}
              />
            </div>
            <div>
              <label className="label"> Y </label>
              <input
                className="number"
                disabled={!edit}
                id={1}
                onChange={onChangeSize}
                onFocus={(e) => {
                  e.target.select()
                }}
                pattern="[0-9]"
                type="number"
                value={ny}
              />
            </div>
            <div>
              <label className="label"> Z </label>
              <input
                className="number"
                disabled={!edit}
                id={2}
                onChange={onChangeSize}
                onFocus={(e) => {
                  e.target.select()
                }}
                pattern="[0-9]"
                type="number"
                value={nz}
              />
            </div>
            <div>
              <label className="label"> [um] </label>
              <input
                className="number"
                disabled={!edit}
                onChange={onChangeResolution}
                onFocus={(e) => {
                  e.target.select()
                }}
                step="0.0001"
                type="number"
                value={cellSize}
              />
            </div>
          </div>

          <div className="inline-holder">
            <Button className="inline-element btn-size" disabled variant="info">
              {sizeGB} GB
            </Button>
            <Button
              className="inline-element btn-type"
              disabled
              variant="success"
            >
              {image.type}
            </Button>
            <div className="checkbox-holder">
              <div className="custom-control custom-checkbox">
                <input
                  checked={bigEndian}
                  className="custom-control-input"
                  disabled
                  id="customCheck2"
                  type="checkbox"
                />
                <label className="custom-control-label" htmlFor="customCheck2">
                  {" "}
                  BigEndian{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="inline-holder">
            <textarea
              className="text"
              disabled={!edit}
              onChange={onChangeComment}
              onFocus={(e) => {
                e.target.select()
              }}
              placeholder="Add comments"
              rows="4"
              style={{ resize: "vertical", width: "100%" }}
              value={comment}
            />
          </div>
        </div>
      </div>
    )
  } else {
    return <div></div>
  }
}
export default memo(PrintDetails)
