import TextField from "@mui/material/TextField"
import React, { useState } from "react"
import {
  Check,
  ChevronRight,
  Edit,
  FolderPlus,
  Plus,
  Trash2,
  X,
} from "react-feather"

import TooltipWrapper from "../../components/TooltipWrapper"
import styles from "../../components/tree/CustomTree.module.css"
import { TypeIcon } from "../../components/tree/TypeIcon"

const EditFolder = (props) => {
  const { onTextChanges, id, handleCancel, label } = props

  const [labelText, setLabelText] = useState(label)

  const handleChangeText = (e) => {
    setLabelText(e.target.value)
  }

  const handleChanges = () => {
    onTextChanges(id, labelText)
    handleCancel()
  }

  return (
    <div className={styles.inputWrapper}>
      <TextField
        className={`${styles.textField}
                ${styles.nodeInput}`}
        onChange={handleChangeText}
        value={labelText}
      />
      {labelText !== "" ? (
        <Check
          active={"t"}
          className="icon icon-red"
          onClick={handleChanges}
          size={16}
        />
      ) : (
        <Edit active={"f"} className="icon icon-grey" size={16} />
      )}
      <X className="icon icon-green" onClick={handleCancel} size={18} />
    </div>
  )
}

const NewFolder = (props) => {
  const { id, handleCancel, label, onTextSubmit } = props

  const [labelText, setLabelText] = useState(label)

  const handleChangeText = (e) => {
    setLabelText(e.target.value)
  }

  const handleSubmit = () => {
    onTextSubmit(id, labelText)
    handleCancel()
  }

  return (
    <div className={styles.inputWrapper}>
      <TextField
        className={`${styles.textField}
                  ${styles.nodeInput}`}
        onChange={handleChangeText}
        value={labelText}
      />
      {labelText !== "" ? (
        <Check
          active={"t"}
          className="icon icon-red"
          onClick={handleSubmit}
          size={16}
        />
      ) : (
        <FolderPlus active={"f"} className="icon icon-grey" size={16} />
      )}
      <X className="icon icon-green" onClick={handleCancel} size={18} />
    </div>
  )
}

const TreeFolderItem = (props) => {
  const {
    handleToggle,
    onCreateFolder,
    onTextCancel,
    onTextSubmit,
    onTextChanges,
  } = props
  const { id, text, isnew, image, droppable } = props.node

  const [isEdit, setEdit] = useState(false)
  const [isNew, setIsNew] = useState(isnew ? true : false)

  const handleCancelEdit = () => {
    setEdit(false)
  }

  const handleOpenEdit = () => {
    setEdit(true)
  }

  const handleCancelNew = () => {
    setIsNew(false)
    onTextCancel(id, true)
  }

  return (
    <div style={{ paddingInlineStart: props.indent }} {...props.dragOverProps}>
      <div className={`tree-node ${styles.root}`}>
        <div
          className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""} ${
            props.isHasChildExpandedWrapper ? styles.isHasChild : ""
          }`}
        >
          {props.hasChild && (
            <div onClick={handleToggle}>
              <ChevronRight className="icon icon-grey" size={16} />
            </div>
          )}
          {!props.node.image && !props.hasChild && (
            <div onClick={handleToggle}>
              <ChevronRight className="icon icon-grey" size={16} />
            </div>
          )}
        </div>
        <div>
          <TypeIcon droppable={droppable} fileType={image} />
        </div>
        {isEdit && (
          <EditFolder
            handleCancel={handleCancelEdit}
            id={id}
            label={text}
            onTextChanges={onTextChanges}
          />
        )}

        {isNew && (
          <NewFolder
            handleCancel={handleCancelNew}
            id={id}
            label={text}
            onTextSubmit={onTextSubmit}
          />
        )}
        {!isEdit && !isNew && (
          <div className={styles.inputWrapper}>
            <TooltipWrapper text={props.node.text}>
              <label
                className={styles.nodeLabel}
                onClick={
                  image
                    ? () => props.onChangeView(props.node.iid)
                    : handleToggle
                }
              >
                {image ? props.node.text.slice(0, 35) : props.node.text}
              </label>
            </TooltipWrapper>

            <div className={styles.inputSubWrapper}>
              <Plus
                className="icon icon-green"
                onClick={() => {
                  if (!props.isOpen) props.onToggle(id)
                  onCreateFolder(id)
                }}
                size={18}
              />
              <Edit
                className="icon icon-green"
                onClick={() => {
                  if (!props.isOpen) props.onToggle(id)
                  handleOpenEdit()
                }}
                size={16}
              />
              <Trash2
                className="icon icon-red"
                onClick={() => props.removeImage(id)}
                size={16}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default TreeFolderItem
