import PropTypes from "prop-types"
import React from "react"

import useImageCollapsePorosityMap from "./ImageCollapsePorosityMap.hook"
import PorosityImageItem from "./PorosityImageItem/PorosityImageItem.view"

const INPUT_PM_TYPE = "pormap"

const ImageCollapsePorosityMap = (props) => {
  const {
    porosityMapNameID,
    onChangeImage,
    porosityImage,
    collapsePorosityMapName,
    togglePorosityMapNameImage,
  } = useImageCollapsePorosityMap(props)

  return (
    <div
      className="d-flex flex-column"
      style={{
        marginBottom: "10px",
      }}
    >
      <a
        aria-controls="collapseAdvanceMenu"
        aria-expanded="true"
        className="parameter_btn"
        onClick={togglePorosityMapNameImage}
        role="button"
      >
        <i
          className={
            collapsePorosityMapName
              ? "fa fa-minus fa-fw custom-btn-icon"
              : "fa fa-plus fa-fw custom-btn-icon"
          }
        />{" "}
        Multiscale
      </a>
      {collapsePorosityMapName && (
        <>
          <h4 className="subtitle" style={{ marginBottom: 5 }}>
            {" "}
            Input Image [{INPUT_PM_TYPE}]
          </h4>
          {porosityImage?.map((e, i) => (
            <PorosityImageItem
              image={e}
              isChecked={porosityMapNameID === e.iid}
              key={i}
              onChange={onChangeImage}
            />
          ))}
        </>
      )}
    </div>
  )
}

ImageCollapsePorosityMap.propTypes = {
  images: PropTypes.object.isRequired,
  inputType: PropTypes.string.isRequired,
  viewID: PropTypes.string.isRequired,
  onChangeImage: PropTypes.func.isRequired,
}

export default ImageCollapsePorosityMap
