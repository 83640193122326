/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from "react"
import { Edit3, Trash2 } from "react-feather"

import useClayFolder from "./ClayFolder.hook"
import { EditRockType } from "./components"
import { useDeleteRockType } from "./components/DeleteRockType/DeleteRockType.hook"
import styles from "./style.module.css"

const ClayFolderView = (props) => {
  const {
    projectRockTypes = [],
    imageRockTypes = [],
    changeViewRockType,
    viewRockType,
    pid,
    networkId,
  } = props
  const { handleDeleteRockType } = useDeleteRockType({ project: { pid } })
  const [selectRockTypes, setSelectRockTypes] = useState(
    projectRockTypes.map((r) => ({ ...r, selected: false })),
  )

  const toggleSelectRockType = (rId) => {
    const updatedRockTypes = selectRockTypes.map((rockType) =>
      rockType.rId === rId
        ? { ...rockType, selected: !rockType.selected }
        : rockType,
    )
    setSelectRockTypes(updatedRockTypes)
    localStorage.setItem(
      `network_${networkId}_${viewRockType}_rockTypes`,
      JSON.stringify(updatedRockTypes),
    )
  }

  const selectedRockTypes = selectRockTypes.filter(
    (rockType) => rockType.selected,
  )

  const deleteRockType = (rId) => {
    const updatedRockTypes = selectRockTypes.filter(
      (rockType) => rockType.rId !== rId,
    )
    setSelectRockTypes(updatedRockTypes)
    handleDeleteRockType(rId)
  }

  useEffect(() => {
    const newSelectRockTypes = projectRockTypes.map((r) => ({
      ...r,
      selected: imageRockTypes?.includes(r?.rId) || false,
    }))

    setSelectRockTypes(newSelectRockTypes)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(imageRockTypes), JSON.stringify(projectRockTypes)])

  return (
    <div className="mt-3 mb-2 w-full">
      {selectRockTypes.map((rockType, index) => (
        <ImageItem
          changeViewRockType={changeViewRockType}
          image={rockType}
          key={index}
          onDelete={() => deleteRockType(rockType.rId)}
          pid={pid}
          selected={selectedRockTypes}
          toggleSelectRockType={toggleSelectRockType}
        />
      ))}
    </div>
  )
}

const ImageItem = (props) => {
  const {
    image,
    changeViewRockType,
    pid,
    toggleSelectRockType,
    selected,
    onDelete,
  } = props
  const { handleToggle, toggle } = useClayFolder()

  const isSelected = selected.some(
    (selectedType) => selectedType.rId === image.rId,
  )

  return (
    <>
      <div className={styles.imageItem}>
        <div className="custom-control custom-checkbox">
          <input
            checked={isSelected}
            className="custom-control-input"
            id={`checkbox-${image.rId}`}
            onChange={() => toggleSelectRockType(image.rId)}
            type="checkbox"
          />
          <label
            className="custom-control-label"
            htmlFor={`checkbox-${image.rId}`}
          ></label>
        </div>

        <div
          className={styles.imageNameWrapper}
          onClick={() => changeViewRockType(image)}
        >
          <p className={styles.imageName}>{image.name}</p>
        </div>

        <div className={styles.rightIconWrapper}>
          <div className="icon icon-blue">
            <Edit3
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleToggle()
              }}
              size={18}
            />
          </div>
          <div className="icon icon-red">
            <Trash2
              onClick={(e) => {
                e.preventDefault()
                onDelete()
              }}
              size={18}
            />
          </div>
        </div>
      </div>

      {toggle && (
        <div className={styles.editRockType}>
          <EditRockType
            onClose={handleToggle}
            pid={pid}
            rId={image.rId}
            rockType={image}
          />
        </div>
      )}
    </>
  )
}

export default ClayFolderView
