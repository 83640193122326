import "./PMTool.css"

import PropTypes from "prop-types"
import React from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Progress } from "reactstrap"

import { LaunchButton } from "../../components"
import ImageFolders from "../../components/ImageFolders"
import PrintImagePM from "../../components/ImagePreview/PrintImagePM/PrintImagePM.view"
import { tooltips } from "../../constants/tooltips"
import { PMConstants, PMReference } from "./PMTool.constant.js"
import { usePmToolView } from "./PMTool.hook.js"

function PMToolView(props) {
  const {
    pid,
    selectedImage,
    images,
    state,
    readyToLaunch,
    outputName,
    viewID,
    job,
    referenceSelected,
    refViewID,
    onChange,
    onKillJob,
    launchJob,
    onChangeOutput,
    onChangeImage,
    setReferenceSelected,
    onFilerNotPM,
    onChangeSegmentation,
    onChangeFaceImage,
  } = usePmToolView(props)

  return (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p3"
            >
              <Col xs={PMConstants.PARAM_COLUMN}> Max Pore Intensity </Col>
              <Col className="d-flex justify-content-end">
                <input
                  className="number-parameter"
                  id={"max"}
                  max={PMConstants.MAX_NORM}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step={PMConstants.STEP_NORM}
                  type="number"
                  value={state.thresholdMax}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.seg_p2}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="seg-tool-p2"
            >
              <Col xs={PMConstants.PARAM_COLUMN}> Min Solid Intensity </Col>
              <Col className="d-flex justify-content-end">
                <input
                  className="number-parameter"
                  id={"min"}
                  min={PMConstants.MIN_NORM}
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step={PMConstants.STEP_NORM}
                  type="number"
                  value={state.thresholdMin}
                />
              </Col>
            </Row>

            <ReactTooltip anchorId="seg-tool-p1" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p2" className="tooltip-msg" />
            <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
          </div>
          <h4 className="subtitle"> Input Image [{PMConstants.INPUT_TYPE}]</h4>
          <ImageFolders
            imageType={PMConstants.INPUT_TYPE}
            images={images}
            jobType={PMConstants.JOB_TYPE}
            onChangeImage={onChangeImage}
            pid={pid || null}
            viewID={viewID}
          />

          <h4 className="subtitle"> Associated Segmentation</h4>
          <ImageFolders
            dependenciesBuildTreeImage={[viewID]}
            filterCb={onFilerNotPM}
            imageType={"8b"}
            images={images}
            isHideStatusIcon={true}
            isShowSelectedImage={true}
            onChangeImage={onChangeSegmentation}
            pid={pid || null}
            viewID={refViewID}
          />

          <div className="checkbox-holder-parameter">
            <Row className="row-parameter">
              <Col xs={PMConstants.PARAM_COLUMN}> Reference </Col>
              <Col className="d-flex justify-content-end">
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    id="dropdown-basic"
                    size="sm"
                    variant="info"
                  >
                    {referenceSelected.name ?? ""}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropdown-font">
                    {Object.values(PMReference || {}).map((item, index) => (
                      <Dropdown.Item
                        key={index}
                        onClick={() => setReferenceSelected(item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>{" "}
              </Col>
            </Row>
          </div>

          <h4 className="subtitle"> Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={onChangeOutput}
              placeholder=" Output File Name"
              type="text"
              value={outputName}
            />
          </div>
        </div>
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton
          jobStatus={job.status}
          killJob={onKillJob}
          launchJob={launchJob}
          readyToLaunch={readyToLaunch}
        />
        <ReactTooltip anchorId="seg-tool-p3" className="tooltip-msg" />
      </div>
      <PrintImagePM
        job={job}
        onChangeFaceImage={onChangeFaceImage}
        pid={pid}
        selectedImage={selectedImage}
      />
    </div>
  )
}

PMToolView.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
}

export default PMToolView
