import axios from "axios"
import React, { useContext, useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { toast } from "react-toastify"

import { authenticate } from "../../helpers/auth"
import ConsoleHelper from "../../helpers/ConsoleHelper"
import { SessionContext } from "../../helpers/SessionContext"

const Login = () => {
  const { session, setSession } = useContext(SessionContext)

  const [formData, setFormData] = useState({
    email: "",
    password1: "",
    textChange: "Sign In",
  })
  const { email, password1 } = formData

  const handleChange = (text) => (e) => {
    let value = e.target.value
    if (text === "email") value = value.toLowerCase()
    setFormData((prevForm) => ({ ...prevForm, [text]: value }))
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (email && password1) {
      setFormData((prevForm) => ({ ...prevForm, textChange: "Submitting" }))
      axios
        .post(`${process.env.REACT_APP_API_URL}/backend/login`, {
          email,
          password: password1,
        })
        .then((res) => {
          ConsoleHelper("Authentication Success:" + email)
          authenticate(res, () => {
            setFormData((prevForm) => ({
              ...prevForm,
              email: "",
              password1: "",
              textChange: "Submitted",
            }))
            setSession(true)
            toast.success(`Hey ${res.data.user.name}, Welcome back!`)
          })
        })
        .catch((err) => {
          setFormData((prevForm) => ({
            ...prevForm,
            password1: "",
            textChange: "Sign In",
          }))
          if (err.response)
            if (err.response.data)
              if (err.response.data.errors)
                toast.error(err.response.data.errors)
              else
                toast.error(
                  "Error connecting to Server - " +
                    err.response.status +
                    " " +
                    err.response.statusText,
                )
            else
              toast.error(
                "Error connecting to Server - " +
                  err.response.status +
                  " " +
                  err.response.statusText,
              )
          else toast.error("Error: No connection to server. Contact support")
        })
    } else {
      toast.error("Please fill all fields")
    }
  }

  return (
    <div className="min-h-full text-gray-900 flex justify-center pt-4">
      {session ? <Navigate to="/projects" /> : null}
      <div
        className="max-w-xl pt-10 pb-20 m-0 sm:m-20 bg-white shadow sm:rounded-lg flex 
      justify-center flex-1"
      >
        <div className="flex flex-col items-center">
          <div className="w-full flex-1 text-indigo-500">
            {/* <div className='flex flex-col items-center'>
                            <button
                                className='w-full max-w-xs font-bold shadow-sm rounded-lg 
                                py-3 bg-indigo-100 text-gray-800 flex items-center justify-center 
                                hover:bg-indigo-200 transition-all duration-300 ease-in-out 
                                outline-none focus:shadow-outline focus:outline-none mt-2'
                                onClick={() => { history.push('/register') }}
                            >
                                <i className='fas fa-user-plus fa 1x w-6  -ml-2 text-indigo-500' />
                                <span className='ml-4'>Sign Up</span>
                            </button>
                        </div>
                        <div className='my-12 border-b text-center'>
                            <div className='leading-none px-2 inline-block text-sm text-gray-600 
                            tracking-wide font-medium bg-white transform translate-y-1/2'>
                                Or sign in with e-mail
                            </div>
                        </div> */}
            <div className="my-4 border-b text-center">
              <div
                className="leading-none px-2 inline-block text-sm text-gray-600 tracking-wide 
              font-medium bg-white transform translate-y-1/2"
              >
                Sign in with e-mail
              </div>
            </div>

            <form className="mx-auto max-w-xs relative" onSubmit={handleSubmit}>
              <input
                className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                focus:border-gray-400 focus:bg-white"
                onChange={handleChange("email")}
                placeholder="Email"
                type="email"
                value={email}
              />
              <input
                className="w-full px-8 py-2 rounded-lg font-medium bg-gray-100 border 
                border-gray-200 placeholder-gray-500 text-sm focus:outline-none 
                focus:border-gray-400 focus:bg-white mt-1"
                onChange={handleChange("password1")}
                placeholder="Password"
                type="password"
                value={password1}
              />
              <button
                className="mt-4 tracking-wide font-semibold bg-indigo-500 text-gray-100 w-full 
                py-2 rounded-lg hover:bg-indigo-700 transition-all duration-300 ease-in-out 
                flex items-center justify-center focus:shadow-outline focus:outline-none"
                type="submit"
              >
                <i className="fas fa-sign-in-alt  w-6  -ml-2" />
                <span className="ml-3">Sign In</span>
              </button>
              <Link
                className="no-underline hover:underline text-indigo-500 text-md text-right 
                absolute right-0  mt-2"
                to="/users/password/forgot"
              >
                Forgot password ?
              </Link>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
