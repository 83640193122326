import React from 'react'
import { CloudOff, Eye, EyeOff, X, } from "react-feather"
import UseAnimations from "react-useanimations"
import airplay from "react-useanimations/lib/airplay"
import waiting from "react-useanimations/lib/loading"
import loading from "react-useanimations/lib/loading2"

import {
    STATUS_FAILED,
    STATUS_FINISHED,
    STATUS_RUNNING,
    STATUS_UNKNOWN,
    STATUS_WAITING,
    UPLOAD_JOB
  } from "../../../../constants/jobnames"

const ImageIcon = ({ job }) => {
    if(!job) return <></>

    switch (job.status) {
        case STATUS_RUNNING: {
            if (job.jobname === UPLOAD_JOB) {
                return <UseAnimations animation={airplay} className="ml-2 mr-2 darkgray-icon" size={20} />
            } else {
                return <UseAnimations animation={loading} className="ml-2 mr-2 darkgray-icon" size={20} />
            }
        }
        case STATUS_WAITING: {
            return <UseAnimations animation={waiting} className="ml-2 mr-2 darkgray-icon" size={20} />
        }
        case STATUS_FINISHED: {
            return <Eye className="ml-2 mr-2 green-icon" size={18} />
        }
        case STATUS_FAILED: {
            return <X className="ml-2 mr-2 red-icon" size={18} />
        }
        case STATUS_UNKNOWN: {
            return <CloudOff className="ml-2 mr-2 darkgray-icon" size={18} />
        }
        default: {
            return <EyeOff className="ml-2 mr-2 gray-icon" size={18} />
        }
    }
}

export default ImageIcon