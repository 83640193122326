import { useMemo } from "react"

export const usePrintImageSgan = ({ parameters }) => {
  const wFocuKernelInterpolates = useMemo(() => {
    if (!parameters.interpolate || !parameters.interpolate) return 0
    return parameters.kernel_size / parameters.interpolate
  }, [parameters])

  return {
    wFocuKernelInterpolates,
  }
}
