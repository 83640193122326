import { createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import { toast } from "react-toastify"

import { WORKFLOW_ERRORS, WORKFLOW_TYPE } from "../constants/workflow"
import { isAuth } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"

const initialState = {
  listWorkflow: [],
}

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    refreshWorkflow: (state, action) => {
      const data = action.payload ?? []

      state.listWorkflow = data
    },
    handleWorkflowErrors: (state, action) => {
      const { type, error } = action.payload ?? {}

      ConsoleHelper("Error handleWorkflowErrors" + (error ?? ""))

      const messageError = {
        [WORKFLOW_ERRORS.GET_WORKFLOW]: "Can not get workflow !",
        [WORKFLOW_ERRORS.CREATE_WORKFLOW]: "Can not create workflow !",
        [WORKFLOW_ERRORS.DELETE]: "Can not delete workflow !",
        [WORKFLOW_ERRORS.ADD_TOOL_TO_MI_WORKFLOW]:
          "Can not add the tool to MI workflow !",
        [WORKFLOW_ERRORS.ADD_IMAGE_TO_MI_WORKFLOW]:
          "Can not add the image to the MI workflow !",
        [WORKFLOW_ERRORS.RUN_MI_WORKFLOW]: "Can not run workflow !",
      }

      toast.error(messageError[type] ?? "An error occurred")
    },
  },
})

export const { setCurrentPID, refreshWorkflow, handleWorkflowErrors } =
  workflowSlice.actions

export const getProjectWorkflow = (payload) => (dispatch) => {
  const { projectId } = payload
  const data = isAuth()
  axios
    .get(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        projectId +
        "/workflows",
    )
    .then((res) => {
      dispatch(refreshWorkflow(res.data))
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.GET_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const createNewWorkflow = (payload) => (dispatch) => {
  const { projectId, data: workflowData } = payload
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        projectId +
        "/create-workflow",
      workflowData,
    )
    .then(() => {
      dispatch(getProjectWorkflow({ projectId }))
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.CREATE_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const deleteWorkflow = (payload) => (dispatch) => {
  const { wid, projectId } = payload
  const data = isAuth()
  axios
    .delete(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        wid +
        "/delete-workflow",
    )
    .then(() => {
      dispatch(getProjectWorkflow({ projectId }))
      toast.success("Delete success !")
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.DELETE, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const addToolToMIWorkflow = (payload) => (dispatch) => {
  const { projectId } = payload
  const data = isAuth()
  axios
    .post(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        projectId +
        "/add-process",
      payload,
    )
    .then(() => {
      dispatch(getProjectWorkflow({ projectId }))
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.ADD_TOOL_TO_MI_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const updateToMIWorkflow = (payload) => (dispatch) => {
  const { wid, dataUpdate, projectId } = payload

  const data = isAuth()
  axios
    .put(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        wid +
        "/update-workflow",
      dataUpdate,
    )
    .then(() => {
      dispatch(getProjectWorkflow({ projectId }))
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.ADD_IMAGE_TO_MI_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const addImagesToMIWorkflow = (payload) => (dispatch) => {
  const { wid, inputImages, projectId } = payload

  let dataUpdate = {
    type: WORKFLOW_TYPE.MULTI_INPUTS,
    inputImages,
  }
  const data = isAuth()
  axios
    .put(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        wid +
        "/update-workflow",
      dataUpdate,
    )
    .then(() => {
      dispatch(getProjectWorkflow({ projectId }))
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.ADD_IMAGE_TO_MI_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export const runWorkflow = (payload) => (dispatch) => {
  const { wid, projectId } = payload
  const data = isAuth()
  axios
    .get(
      process.env.REACT_APP_API_URL +
        "/backend/" +
        data.email +
        "/" +
        wid +
        "/run-workflows",
    )
    .then(() => {
      setTimeout(() => dispatch(getProjectWorkflow({ projectId })), 300)
    })
    .catch((error) => {
      const payload = { type: WORKFLOW_ERRORS.RUN_MI_WORKFLOW, error }
      dispatch(handleWorkflowErrors(payload))
    })
}

export default workflowSlice.reducer
