import "./Header.css"

import React, { useContext } from "react"
import { Button, Nav, Navbar, NavDropdown } from "react-bootstrap"
import { toast } from "react-toastify"

import { isAuth, signout } from "../helpers/auth"
import SessionContext from "../helpers/SessionContext"

const Header = () => {
  const { session, setSession } = useContext(SessionContext)

  const Options = () => {
    if (session) {
      return (
        <>
          <Nav.Link href="/projects"> Dashboard </Nav.Link>
          {/* <Nav.Link href="/"> Help </Nav.Link> */}
          <NavDropdown alignright="true" id="basic-nav-dropdown" title="User">
            {isAuth().role === "admin" ? (
              <NavDropdown.Item href="/admin"> Admin Config </NavDropdown.Item>
            ) : null}
            <NavDropdown.Item href="/settings"> Settings </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item
              href="/"
              onClick={() =>
                signout(() => {
                  toast.error("Logout Successfully")
                  setSession(false)
                })
              }
            >
              Log Out
            </NavDropdown.Item>
          </NavDropdown>
        </>
      )
    } else {
      return (
        <>
          {/* <Nav.Link href="/"> Prices </Nav.Link> */}
          <Nav.Link href="/#contact-form"> Contact us </Nav.Link>
          <Button
            className="align-middle flex items-center font-medium"
            href="/login"
            variant="success"
          >
            {" "}
            Log In{" "}
          </Button>
        </>
      )
    }
  }

  return (
    <Navbar className="navbar" expand="lg" sticky="top">
      <a className="navbar-left" href="/">
        <img
          alt=""
          className="mainlogo"
          src={require("../images/SmartrocksLogo_White_Transparent_2.png")}
        />
      </a>
      {/* <Navbar.Brand className="mainbrand" href="/projects"> SmartRocks </Navbar.Brand> */}
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto"></Nav>
        <Nav className="mr-sm-2">
          <Options session={session} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Header
