import React, { memo } from 'react'

import { STATUS_FINISHED } from '../../../constants/jobnames'
import ImageWrapper from '../ImageWrapper/ImageWrapper.view'
import { PrintImagePMProvider } from './PrintImagePM.context'
import PrintImagePMItem from './PrintImagePMItem/PrintImagePMItem.view'


const PrintImagePM = ({ 
  job,
  pid,    
  selectedImage,
  onChangeFaceImage
}) => {

  return (
    <div className="images-container" style={{
      width: "100%"
    }}>
      <ImageWrapper 
        job={selectedImage?.jobs["extra"]}
        title="Input"
      >
        <PrintImagePMItem
          isShowOutputImage={false}
          job={selectedImage?.jobs["extra"]}
          pid={pid}
          selectedImage={selectedImage}
        />
        </ImageWrapper>
        <ImageWrapper 
          job={job}
          title="Output"
        >
        {
          job?.status === STATUS_FINISHED ?
            <PrintImagePMItem
              isShowOutputImage={true}
              job={job}
              onChangeFaceImage={onChangeFaceImage}
              pid={pid}
              selectedImage={selectedImage}
            />
          :  <PrintImagePMItem/>
          }
      </ImageWrapper>
    </div>
  )
}

const PrintImagePMContainer = (props) => {
  return (
    <PrintImagePMProvider>
      <PrintImagePM {...props} />
    </PrintImagePMProvider>
  )
}

export default memo(PrintImagePMContainer)