import React from "react"
import { useDispatch } from "react-redux"

import { updateRockType } from "../../../../../../actions/projectSlice"

export const useEditRockType = ({ pid, rId, onClose, rockType }) => {
  const dispatch = useDispatch()
  const [rockTypeProperties, setRockTypeProperties] = React.useState({
    name: rockType?.name || "",
    label: rockType?.label ?? 1,
    a: rockType?.a ?? 0.0,
    b: rockType?.b ?? 0.0,
    aMin: rockType?.aMin ?? 0.0,
    aMax: rockType?.aMax ?? 0.0,
    cemExp: rockType?.cemExp ?? 0.0,
  })

  const handleChangeRockTypeProperty = (e) => {
    const { name, value } = e.target
    setRockTypeProperties((prevState) => ({
      ...prevState,
      [name]: name === "name" || name === "label" ? value : parseFloat(value),
    }))
  }

  const handleUpdateRockType = () => {
    const payload = {
      rId: rId,
      ...rockTypeProperties,
    }
    dispatch(updateRockType({ pid, data: payload })).then(() => {
      onClose()
    })
  }

  return {
    rockTypeProperties,
    handleChangeRockTypeProperty,
    handleUpdateRockType,
  }
}
