export const formatPermValue = (value) => {
  if (value === null || value === undefined || isNaN(value)) {
    return "0.000"
  }
  const numValue = Number(value)

  if (numValue > 100) return Math.round(numValue)
  if (numValue > 10) return parseFloat(numValue.toFixed(1))
  if (numValue > 1) return parseFloat(numValue.toFixed(2))
  return parseFloat(numValue.toFixed(3))
}
