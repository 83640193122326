import React, { useEffect, useMemo } from "react"

import { isSameSizeImage } from "../../helpers/IsSameSizeImage"
const INPUT_PM_TYPE = "pormap"

const useImageCollapsePorosityMap = (props) => {
  const {
    images,
    inputType = [],
    porosityMapNameID,
    onChangeImage,
    viewID,
  } = props
  const [collapsePorosityMapName, setCollapsePorosityMapName] =
    React.useState(false)

  const togglePorosityMapNameImage = () => {
    setCollapsePorosityMapName(!collapsePorosityMapName)
  }

  useEffect(() => {
    if (porosityMapNameID !== "" && !collapsePorosityMapName) {
      setCollapsePorosityMapName(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [porosityMapNameID])

  const porosityImage = useMemo(() => {
    const imageView = images[viewID]
    if (!imageView) return []

    const _inputType = Array.isArray(inputType) ? inputType : [inputType]
    const imageFilter = Object.entries(images || {}).reduce(
      (acc, [key, value]) => {
        if (!_inputType.includes(value.type)) return acc
        if (
          value.createByTool === INPUT_PM_TYPE &&
          isSameSizeImage(imageView, value)
        )
          acc[key] = value
        return acc
      },
      {},
    )

    return Object.values(imageFilter)
  }, [images, inputType, viewID])

  return {
    porosityImage,
    porosityMapNameID,
    onChangeImage,
    collapsePorosityMapName,
    togglePorosityMapNameImage,
  }
}

export default useImageCollapsePorosityMap
