import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"

import { doJob, getJobStatus, killJob } from "../../actions/projectSlice.js"
import {
  PM_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../../constants/jobnames"
import { getLocalStorage, setLocalStorage } from "../../helpers/auth"
import { isSameSizeImage } from "../../helpers/IsSameSizeImage.js"
import useJobNotification from "../../hooks/useJobNotification"
import { killJobFn, sendUpdateRequestFn } from "../../utils/jobs"
import { PMConstants, PMReference } from "./PMTool.constant.js"

const JOB_PREFIX = PMConstants.JOB_PREFIX

const INPUT_TYPE = PMConstants.INPUT_TYPE
const JOB_TYPE = PM_JOB
const POLLING_TIME = PMConstants.POLLING_TIME

export const usePmToolView = (props) => {
  const { pid, images } = props?.project || {}

  const dispatch = useDispatch()

  const [state, setState] = useState({
    thresholdMin: parseFloat(
      getLocalStorage("PM_threshold_min", PMConstants.THRESHOLD_MIN),
    ),
    thresholdMax: parseFloat(
      getLocalStorage("PM_threshold_max", PMConstants.THRESHOLD_MAX),
    ),
    nx: 1,
    ny: 1,
    nz: 1,
    cellSize: 1,
  })

  const memoizedImage = useMemo(() => {
    return images
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(images)])

  const [viewID, setViewID] = useState(() => {
    const nImages = Object.keys(images).filter(
      (iid) => images[iid].type === INPUT_TYPE,
    )
    if (nImages.length > 0) return nImages[0]
    return ""
  })

  const selectedImage = useMemo(() => {
    return memoizedImage[viewID]
  }, [memoizedImage, viewID])

  const [outputName, setOutputName] = useState(() => {
    if (selectedImage) {
      return JOB_PREFIX + selectedImage.name
    }
    return ""
  })
  const [sliderResetCount, setSliderResetCount] = useState(0)
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })
  const [referenceSelected, setReferenceSelected] = useState(() => {
    if (selectedImage) {
      const _parameters =
        selectedImage?.jobs?.[JOB_TYPE]?.parameters?.Parameters
      const isReferenceSelectedPM =
        _parameters?.referenceSelected?.value === PMReference.PM.value
      return isReferenceSelectedPM ? PMReference.PM : PMReference.SEG
    }
    return PMReference.SEG
  })
  const [refViewID, setRefViewID] = useState(() => {
    if (selectedImage) {
      const _parameters =
        selectedImage?.jobs?.[JOB_TYPE]?.parameters?.Parameters
      const currentPm = Object.values(memoizedImage).find(
        (image) => image.filename === _parameters?.associatedImageFileName,
      )
      if (currentPm) {
        return currentPm.iid
      }
    }

    return PMReference.SEG
  })

  const intervalId = useRef(null)
  const isJobFinished = useRef(false)

  useJobNotification({ job })

  const sendUpdateRequest = useCallback(
    (pid, iid, jid, job_type) =>
      sendUpdateRequestFn(getJobStatus)(pid, iid, jid, job_type),
    [],
  )

  const sendKillRequest = useCallback(
    (pid, iid, jid, job_type, intervalId) =>
      killJobFn(killJob)(pid, iid, jid, job_type, intervalId),
    [],
  )

  const pollApi = useCallback(() => {
    if (!job || !Object.keys(job).length) return

    if (
      (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
      job.jid === undefined
    ) {
      clearInterval(intervalId.current)
    } else {
      dispatch(sendUpdateRequest(pid, viewID, job.jid, JOB_TYPE))
    }
  }, [job, pid, sendUpdateRequest, viewID, dispatch])

  const updateParameter = useCallback(({ min, max, nx, ny, nz, cellSize }) => {
    setState((old) => {
      return {
        ...old,
        thresholdMin: min,
        thresholdMax: max,
        nx: nx,
        ny: ny,
        nz: nz,
        cellSize: cellSize,
      }
    })
  }, [])

  const changeView = useCallback(
    (index) => {
      clearInterval(intervalId.current)
      setViewID(index)
      const image = images[index]

      const _parameters = image?.jobs?.[JOB_TYPE]?.parameters?.Parameters
      const isReferenceSelectedPM =
        _parameters?.referenceSelected?.value === PMReference.PM.value

      if (image) {
        updateParameter({
          min: _parameters?.max ?? PMConstants.THRESHOLD_MIN, // Parameters.max is right
          max: _parameters?.min ?? PMConstants.THRESHOLD_MAX, // Parameters.min is right
          nx: image.nx,
          ny: image.ny,
          nz: image.nz,
          cellSize: image.cellSize,
        })

        // In case of PMReference.PM, we need to set the reference image
        const currentPm = Object.values(images).find(
          (image) => image.filename === _parameters?.associatedImageFileName,
        )
        if (currentPm) {
          setReferenceSelected(
            isReferenceSelectedPM ? PMReference.PM : PMReference.SEG,
          )
          setRefViewID(currentPm.iid)
          setOutputName(_parameters.outputName)
          return
        }
      }

      // Reset the output name
      setOutputName(JOB_PREFIX + image.name)
      setSliderResetCount(sliderResetCount + 1)

      // Update RefViewID and ReferenceSelected if the current image is the reference image
      if (!isReferenceSelectedPM) {
        setReferenceSelected(PMReference.SEG)
      }

      if (refViewID !== "") {
        setRefViewID("")
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [images, sliderResetCount],
  )

  const onChangeImage = useCallback(
    (_value) => {
      changeView(_value)
    },
    [changeView],
  )

  const onChangeOutput = (event) => {
    setOutputName(event.currentTarget.value)
  }

  const readyToLaunch = useMemo(() => {
    if (viewID === "") return false

    return (
      selectedImage !== null &&
      job.status !== STATUS_RUNNING &&
      job.status !== STATUS_WAITING &&
      outputName.length > 0
    )
  }, [viewID, job.status, selectedImage, outputName])

  const getLaunchProps = useCallback(() => {
    let confimap = false
    if (job.status === STATUS_FINISHED) confimap = true
    return {
      thresholdMin: state.thresholdMin,
      thresholdMax: state.thresholdMax,
      confimap,
      nx: state.nx,
      ny: state.ny,
      nz: state.nz,
      cellSize: state.cellSize,
      referenceSelected: referenceSelected,
      associatedImage: refViewID,
    }
    // eslint-disable-next-line max-len
  }, [
    job.status,
    refViewID,
    referenceSelected,
    state.cellSize,
    state.nx,
    state.ny,
    state.nz,
    state.thresholdMax,
    state.thresholdMin,
  ])

  const launchJob = useCallback(() => {
    if (!readyToLaunch) {
      return
    }

    let properties = getLaunchProps()
    properties = {
      ...properties,
      out_path: outputName,
    }

    const payload = {
      pid: pid,
      iid: selectedImage.iid,
      jobname: JOB_TYPE,
      data: properties,
    }

    dispatch(doJob(payload))
  }, [
    dispatch,
    getLaunchProps,
    outputName,
    pid,
    selectedImage?.iid,
    readyToLaunch,
  ])

  // sendKillRequest
  const onKillJob = useCallback(() => {
    dispatch(
      sendKillRequest(pid, viewID, job.jid, JOB_TYPE, intervalId.current),
    )
  }, [dispatch, intervalId, job.jid, pid, sendKillRequest, viewID])

  const onChange = useCallback((e) => {
    let number = Number(e.target.value)
    if (isNaN(number)) number = 0
    number = Math.min(Math.max(number, 0.0), PMConstants.MAX_NORM)

    if (e.target.id === "min") {
      setState((old) => {
        return {
          ...old,
          thresholdMin: number,
        }
      })
      setLocalStorage("PM_threshold_min", number)
    }
    if (e.target.id === "max") {
      setState((old) => {
        return {
          ...old,
          thresholdMax: number,
        }
      })
      setLocalStorage("PM_threshold_max", number)
    }
  }, [])

  useEffect(() => {
    if (!isJobFinished.current && job.status === STATUS_FINISHED) {
      isJobFinished.current = true // warning dont remove with current, if you remove infinity get current project
      // props.getProject(pid)
      clearInterval(intervalId.current)
      return
    }

    if (job?.status === STATUS_RUNNING || job?.status === STATUS_WAITING) {
      isJobFinished.current = false
      intervalId.current = setInterval(pollApi, POLLING_TIME)
    }

    return () => clearInterval(intervalId.current)
  }, [job?.status, pollApi])

  useEffect(() => {
    let nImages = Object.keys(images).filter(
      (iid) => images[iid].type === INPUT_TYPE,
    )
    if (nImages.length > 0) changeView(nImages[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const image = images[viewID]
    if (!image?.jobs?.[JOB_TYPE]) return

    if (
      job.status !== image.jobs[JOB_TYPE].status ||
      job.progress !== image.jobs[JOB_TYPE].progress
    ) {
      setJob(image.jobs[JOB_TYPE])
    }
  }, [viewID, images, job])

  const onFilerNotPM = useCallback(
    (node) => {
      return (
        node.createByTool !== PMConstants.JOB_TYPE &&
        isSameSizeImage(node, selectedImage)
      )
    },
    [selectedImage],
  )

  const onChangeSegmentation = useCallback(
    (_refViewID) => {
      setRefViewID((old) => {
        if (old === _refViewID) {
          if (referenceSelected.value === PMReference.PM.value) {
            setReferenceSelected(PMReference.SEG)
          }
          return ""
        }
        return _refViewID
      })
    },
    [referenceSelected.value],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeFaceImage = useCallback((_faceImage) => {
    setSelectedImage(_faceImage)
  })

  return {
    pid,
    job,
    state,
    images: memoizedImage,
    viewID,
    outputName,
    readyToLaunch,
    selectedImage,
    referenceSelected,
    refViewID,
    onChange,
    onKillJob,
    launchJob,
    onChangeOutput,
    onChangeImage,
    setReferenceSelected,
    onFilerNotPM,
    onChangeSegmentation,
    onChangeFaceImage,
  }
}
