import InnerHTML from "dangerously-set-html-content"
import PropTypes from "prop-types"
import React from "react"
import { Col, Row, Tab, Tabs } from "react-bootstrap"
import { connect } from "react-redux"
import { Progress } from "reactstrap"

import {
  doJob,
  getJobStatus,
  getProject,
  killJob,
} from "../../actions/projectSlice"
import ImageCollapsePorosityMap from "../../components/ImageCollapsePorosityMap/ImageCollapsePorosityMap.view"
import ImageFolders from "../../components/ImageFolders"
import { NETWORK_JOB } from "../../constants/jobnames"
import { LaunchButton, NetworkData } from "./components"
import { NetworkConstants } from "./NetworkTool.constant"
import { NetworkContextProvider } from "./NetworkTool.ctx"
import { useNetworkTool } from "./NetworkTool.hook"
const INPUT_TYPE = NetworkConstants.INPUT_TYPE

function NetworkTool() {
  const {
    job,
    onChange,
    onChangeNotPM,
    onChangeImage,
    onChangePorosityMapNameImage,
    memoizedPid,
    microPorosity,
    memoizedImage,
    viewID,
    porosityMapNameID,
  } = useNetworkTool()

  return (
    <div className="toolTab network-tool" id="netex">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row className="row-parameter">
              <Col xs={8}> Micro-Phase Porosity </Col>
              <Col>
                <input
                  className="number-parameter"
                  id="microPorosity"
                  onChange={onChange}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.1"
                  type="number"
                  value={microPorosity}
                />
              </Col>
            </Row>
          </div>
          <h4 className="subtitle"> Input Image [{INPUT_TYPE}]</h4>
          <ImageFolders
            filterCb={onChangeNotPM}
            imageType={INPUT_TYPE}
            images={memoizedImage}
            jobType={NETWORK_JOB}
            onChangeImage={onChangeImage}
            pid={memoizedPid}
            viewID={viewID}
          />
          <ImageCollapsePorosityMap
            images={memoizedImage}
            inputType={INPUT_TYPE}
            onChangeImage={onChangePorosityMapNameImage}
            porosityMapNameID={porosityMapNameID}
            viewID={viewID}
          />
        </div>

        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton />
      </div>
      <div className="toolResultWrapper input-grid">
        <div className="tool-holder-parameter centering">
          <div className="parameters-holder-results">
            <div className="inline-holder-parameter">
              <h5 className="title"> Results </h5>
            </div>

            <Tabs
              className="nav nav-tabs"
              defaultActiveKey="tab-properties"
              id="network-tabs"
            >
              <Tab className="p-3" eventKey="tab-properties" title="Properties">
                <NetworkData />
              </Tab>
              <Tab eventKey="tab-cross-plots" title="Cross Plots">
                <div className="xPlot-holder-content">
                  {job?.results?.xplot ? (
                    <div className="xplot-image">
                      <InnerHTML
                        className="adjustPositionNetex"
                        html={job?.results?.xplot}
                        key={job.jid}
                      />
                    </div>
                  ) : (
                    <h5 className="no-results-netex">No Results Yet</h5>
                  )}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  )
}

const NetworkContainer = (props) => {
  return (
    <NetworkContextProvider {...props}>
      <NetworkTool />
    </NetworkContextProvider>
  )
}

NetworkContainer.propTypes = {
  project: PropTypes.shape({
    pid: PropTypes.string.isRequired,
    images: PropTypes.object.isRequired,
  }).isRequired,
  doJob: PropTypes.func.isRequired,
  killJob: PropTypes.func.isRequired,
  getJobStatus: PropTypes.func.isRequired,
  getProject: PropTypes.func.isRequired,
}

export default connect(null, { doJob, killJob, getJobStatus, getProject })(
  NetworkContainer,
)
