import React from "react"

const PorosityImageItem = ({ isChecked, onChange, image }) => {
  if (!image) return <></>

  return (
    <label className="custom-control custom-checkbox">
      <input
        checked={isChecked}
        className="custom-control-input"
        onChange={() => {
          if (!onChange) return
          onChange(image.iid)
        }}
        type="checkbox"
      />

      <p
        className="label-parameter custom-control-label"
        style={{ marginLeft: 10, wordBreak: "break-word", textAlign: "left" }}
      >
        {image.name ?? ""}
      </p>
    </label>
  )
}

export default PorosityImageItem
