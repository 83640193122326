import React from "react"

import {
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_WAITING,
} from "../constants/jobnames"

export const LaunchButton = ({
  jobStatus,
  adjust,
  readyToLaunch,
  launchJob,
  killJob,
}) => {
  if (jobStatus !== STATUS_RUNNING && jobStatus !== STATUS_WAITING)
    if (adjust === true && jobStatus === STATUS_FINISHED)
      return (
        <button
          className="btn btn-info btn-block"
          disabled={!readyToLaunch}
          onClick={launchJob}
          type="button"
        >
          {" "}
          Re-Adjust{" "}
        </button>
      )
    else
      return (
        <button
          className="btn btn-success btn-block"
          disabled={!readyToLaunch}
          onClick={launchJob}
          type="button"
        >
          {" "}
          Launch{" "}
        </button>
      )
  else
    return (
      <button
        className="btn btn-warning btn-block"
        onClick={killJob}
        type="button"
      >
        {" "}
        Cancel{" "}
      </button>
    )
}
