import React from "react"

import { STATUS_FINISHED } from "../../../constants/jobnames"
import ImageWrapper from "../ImageWrapper/ImageWrapper.view"
import { usePrintImageSgan } from "./PrintImageSgan.hook"
import PrintImageSganInput from "./PrintImageSganInput/PrintImageSganInput.view"
import PrintImageSganOutput from "./PrintImageSganOutput/PrintImageSganOutput.view"

const PrintImageSgan = ({ files, job, pid, selectedImage, parameters }) => {
  const { wFocuKernelInterpolates } = usePrintImageSgan({ job, parameters })

  return (
    <div className="images-container">
      <ImageWrapper job={job} title="Input">
        <PrintImageSganInput
          files={files}
          wFocuKernelInterpolates={wFocuKernelInterpolates}
        />
      </ImageWrapper>
      <ImageWrapper job={job} title="Output">
        {job.status === STATUS_FINISHED ? (
          <PrintImageSganOutput
            job={job}
            pid={pid}
            selectedImage={selectedImage}
          />
        ) : (
          <PrintImageSganInput />
        )}
      </ImageWrapper>
    </div>
  )
}

export default PrintImageSgan
