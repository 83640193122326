import "./Parameters.css"

import Slider from "@mui/material/Slider"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Col, Dropdown, Navbar, Row } from "react-bootstrap"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Tooltip as ReactTooltip } from "react-tooltip"
import { Progress } from "reactstrap"

import {
  doJob,
  getJobStatus,
  getProject,
  getProjectAsync,
  killJob,
  uploadImage,
} from "../actions/projectSlice"
import PrintImageSgan from "../components/ImagePreview/PrintImageSgan/PrintImageSgan.view"
import {
  SGAN_JOB,
  STATUS_FINISHED,
  STATUS_RUNNING,
  STATUS_UNKNOWN,
  STATUS_WAITING,
} from "../constants/jobnames"
import { tooltips } from "../constants/tooltips"
import { isAuth } from "../helpers/auth"
import ConsoleHelper from "../helpers/ConsoleHelper"
import { MakeFileFormLinks } from "../helpers/MakeFileFromLinkHelper"
import useInterval from "../hooks/useInterval"
import useJobNotification from "../hooks/useJobNotification"
import ImageList from "./ImageList"

const POLLING_TIME = 3000
const JOB_TYPE = SGAN_JOB
const IMAGE_TYPE = SGAN_JOB
const PARAM_COLUMN = 6
const mappingModel = {
  sgan: "2D - Dev_v1.0",
  diff: "2D - Diff_v1.0",
}

const MIN_GRAY_THRESHOLD = 0.01
const MAX_GRAY_THRESHOLD = 0.99
const GRAY_THRESHOLD_STEP = 0.01
const DEFAULT_GRAY_THRESHOLD = [MIN_GRAY_THRESHOLD, MAX_GRAY_THRESHOLD]
const models = Object.values(mappingModel)

function SGANTool(props) {
  const [parameters, setParameters] = useState({
    model: "",
    interpolate: 1.0,
    isGray: true,
    gray_threshold: DEFAULT_GRAY_THRESHOLD,
    doThreshold: true,
    thresholds: "128",
    cell_size: 1.0,
    output_size: [500],
    output_name: "2Dto3D_c500_1um.raw",
    kernel_size: 64,
    epochs: 100,
  })

  const pid = props?.project?.pid
  const [outputSizeString, setOutputSizeString] = useState("500")
  const [readyToLaunch, setReadyToLaunch] = useState(false)
  const [collapseAdvanceMenu, setCollapseAdvanceMenu] = useState(false)
  const [files, setFiles] = useState([])
  const [viewID, setViewID] = useState("")
  const [job, setJob] = useState({ status: STATUS_UNKNOWN, progress: 0 })

  const navRef = useRef(null)
  const intervalId = useRef(null)
  const refInputFile = useRef(null)
  const user = isAuth().email

  const handleGrayThreshold = (event, newValue) => {
    if (!Array.isArray(newValue)) {
      return
    }

    let _grayThreshold = [Math.max(newValue[0], 0), Math.min(newValue[1], 1.0)]
    setParameters({
      ...parameters,
      gray_threshold: _grayThreshold,
    })
  }

  const resetParameter = (_parameters, viewID) => {
    let parameterDefault = {
      interpolate: _parameters?.interpolate || 1.0,
      isGray: _parameters?.isGray === false ? false : true,
      doThreshold: _parameters?.doThreshold === false ? false : true,
      thresholds: _parameters?.thresholds?.join(",") || "128",
      cell_size: _parameters?.cell_size || 1.0,
      output_size: _parameters?.output_size || [500],
      output_name: _parameters?.save_path || "2Dto3D_c500_1um.raw",
      kernel_size: _parameters?.train_crop_size || 64,
      epochs: _parameters?.num_epochs || 100,
      model: _parameters?.model ? mappingModel[_parameters.model] : models[0],
      gray_threshold: _parameters?.gray_threshold || [0.1, 0.1],
    }

    const _images =
      images[viewID]?.jobs?.[JOB_TYPE]?.parameters?.inputFileName || []
    const _jid = images[viewID]?.jobs?.[JOB_TYPE]?.jid || undefined

    const links = _images.map(
      (imagePath) =>
        process.env.REACT_APP_API_URL +
        "/backend/" +
        user +
        "/" +
        pid +
        "/" +
        viewID +
        "/" +
        JOB_TYPE +
        "/" +
        imagePath +
        "?train=true" +
        "&jid=" +
        _jid,
    )

    const makeFileFormLinks = new MakeFileFormLinks(links)
    makeFileFormLinks.execute().then((files) => {
      refInputFile.current.files = files
      setFiles(files)
    })

    setParameters(parameterDefault)
    setOutputSizeString(parameterDefault.output_size?.join(",") || "")
  }

  useEffect(() => {
    setParameters({ ...parameters, model: models[0] })

    return () => {
      clearInterval(intervalId.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    let image = props.project.images[viewID]
    if (image !== undefined && image.jobs !== undefined) {
      if (image.jobs[JOB_TYPE] !== undefined) {
        if (
          job.status !== image.jobs[JOB_TYPE].status ||
          job.progress !== image.jobs[JOB_TYPE].progress ||
          job.jid !== image.jobs[JOB_TYPE].jid
        ) {
          setJob(image.jobs[JOB_TYPE])
        }
      } else {
        setJob({ status: STATUS_UNKNOWN, progress: 0 })
      }
    }
  }, [props?.project?.images, viewID, job])

  useJobNotification({ job })

  function sendUpdateRequest(iid, jid) {
    if (iid !== "" && iid !== undefined && jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        iid,
        jid,
        jobname: JOB_TYPE,
      }
      props.getJobStatus(payload)
    } else {
      ConsoleHelper("Not defined JID, [" + jid + "]")
    }
  }

  const [startPollApi, setStartPollApi] = useState(false)
  useInterval(pollApi, startPollApi ? POLLING_TIME : null)

  function pollApi() {
    if (Object.keys(job).length) {
      if (
        (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING) ||
        job.jid === undefined
      ) {
        if (job.status === STATUS_FINISHED) {
          setStartPollApi(false)
          const _images = job?.parameters?.inputFileName || []

          const links = _images.map(
            (imagePath) =>
              process.env.REACT_APP_API_URL +
              "/backend/" +
              user +
              "/" +
              pid +
              "/" +
              viewID +
              "/" +
              JOB_TYPE +
              "/" +
              imagePath +
              "?train=true" +
              "&jid=" +
              job.jid,
          )

          const makeFileFormLinks = new MakeFileFormLinks(links)
          makeFileFormLinks.execute().then((files) => {
            refInputFile.current.files = files
            setFiles(files)
          })
          props.getProject(props.project.pid)
        }
      } else {
        sendUpdateRequest(viewID, job.jid)
      }
    }
  }

  function changeView(iid) {
    clearInterval(intervalId.current)
    setViewID(iid)
    let image = props.project.images[iid]
    const imageParameter = image?.jobs?.[JOB_TYPE]?.parameters
    resetParameter(imageParameter, iid)
    if (image.jobs !== undefined && image.jobs[JOB_TYPE] !== undefined) {
      let job = image.jobs[JOB_TYPE]
      setJob(job)
      sendUpdateRequest(iid, job.jid)
      if (
        image.jobs[JOB_TYPE].status === STATUS_RUNNING ||
        image.jobs[JOB_TYPE].status === STATUS_WAITING
      ) {
        intervalId.current = setInterval(pollApi, POLLING_TIME)
      }
    } else {
      setJob({ status: STATUS_UNKNOWN, progress: 0 })
    }
  }

  const checkLaunch = useCallback(() => {
    // console.log(" SGAN ", job, " ", parameters, " ", files)
    if (
      job.status !== STATUS_WAITING &&
      job.status !== STATUS_RUNNING &&
      parameters.output_name.length > 0 &&
      (files.length || job?.parameters?.inputFileName?.length)
    ) {
      setReadyToLaunch(true)
    } else {
      setReadyToLaunch(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files?.length, job?.status, parameters?.output_name?.length])

  useEffect(() => {
    checkLaunch()
  }, [job, parameters, files, checkLaunch])

  function onChangeFile(e) {
    const inputFiles = e.target.files
    const fileExtension = inputFiles[0]?.type.split("/")[1]
    if (fileExtension?.includes("png") || fileExtension?.includes("PNG")) {
      setFiles(inputFiles)
      resetViewID("")
    } else {
      setFiles([])
      toast.error("Please input png / PNG images")
    }
  }

  function resetViewID(viewID) {
    setViewID(viewID)
    setJob({ status: STATUS_UNKNOWN, progress: 0 })
  }

  const onLaunchJob = () => {
    let parametersCopy = JSON.parse(JSON.stringify(parameters))
    ConsoleHelper("Launching JoB", parametersCopy)
    const iid = parametersCopy.output_name.split(".").join("_")
    resetViewID(iid)

    // Parse thresholds
    let thresholds = []
    try {
      thresholds = parametersCopy.thresholds.split(",").map((item) => {
        const num = Number(item)
        if (isNaN(num)) {
          throw new Error(`Invalid number: ${item}`)
        }
        return num
      })
    } catch (error) {
      toast.error("Error parsing thresholds, please verify")
      return
    }

    let newImage = {
      iid,
      filename: parametersCopy.output_name,
      name: parametersCopy.output_name,
      extension: "RAW",
      type: IMAGE_TYPE,
    }
    let payload = {
      pid: props.project.pid,
      iid,
      jobname: JOB_TYPE,
      properties: parametersCopy,
      newImage,
    }
    payload.properties.thresholds = thresholds

    // Copy file
    const data = new FormData()
    for (var x = 0; x < files.length; x++) data.append("file", files[x])
    data.append("newImage", JSON.stringify(newImage))
    data.append("payload", JSON.stringify(payload))
    payload = { ...payload, data }

    props
      .uploadImage(payload)
      .then((jobData) => {
        ConsoleHelper("JobData", jobData)
        props.getProjectAsync(props.project.pid).then(() => {
          setJob(jobData) //update new job
          setStartPollApi(true) // start polling
        })
      })
      .catch((e) => {
        toast.error("Error uploading images")
        ConsoleHelper("Error uploading images", e)
      })
  }

  const onKillJob = () => {
    clearInterval(intervalId.current)
    if (job.jid !== undefined) {
      const payload = {
        pid: props.project.pid,
        jid: job.jid,
        iid: viewID,
        jobname: JOB_TYPE,
      }
      ConsoleHelper("Killing JoB: " + payload)
      props.killJob(payload)
    } else {
      ConsoleHelper("Not defined Cancel JID, [" + job.jid + "]")
    }
  }

  const onChangeInterpolate = (e) => {
    const interpolate = Number(e.target.value)
    const resolution = parseFloat(parameters.cell_size / interpolate)
      .toFixed(2)
      .split(".")
      .join("p")
    const sizeString =
      parameters.output_size.length < 3
        ? `c${parameters.output_size[0]}`
        : `${parameters.output_size[0]}_${parameters.output_size[1]}_${parameters.output_size[2]}`
    setParameters({
      ...parameters,
      interpolate,
      output_name: `2Dto3D_c${sizeString}_${resolution}um.raw`,
    })
    resetViewID("")
  }

  const onChangeThresholds = (e) => {
    const thresholds = e.target.value
    setParameters({
      ...parameters,
      thresholds,
    })
  }

  const onChangeCellSize = (e) => {
    const cell_size = Number(e.target.value)
    const resolution = parseFloat(cell_size / parameters.interpolate)
      .toFixed(2)
      .split(".")
      .join("p")
    const sizeString =
      parameters.output_size.length < 3
        ? `c${parameters.output_size[0]}`
        : `${parameters.output_size[0]}_${parameters.output_size[1]}_${parameters.output_size[2]}`

    setParameters({
      ...parameters,
      cell_size: cell_size,
      output_name: `2Dto3D_${sizeString}_${resolution}um.raw`,
    })
    resetViewID("")
  }

  const onChangeOutputSize = (e) => {
    const outputSizeString = e.target.value
    let size = []
    try {
      size = outputSizeString.split(",").map((item) => {
        const num = Number(item)
        if (isNaN(num)) {
          throw new Error(`Invalid number: ${item}`)
        }
        if (num < 100) return 100
        if (num > 1200) return 1200
        return num
      })
    } catch (error) {
      toast.error(
        "Error parsing output size, please verify (100 < size < 1000))",
      )
      return
    }
    const output_size = size
    const resolution = parseFloat(parameters.cell_size / parameters.interpolate)
      .toFixed(2)
      .split(".")
      .join("p")
    const sizeString =
      output_size.length < 3
        ? `c${output_size[0]}`
        : `${output_size[0]}_${output_size[1]}_${output_size[2]}`
    setParameters({
      ...parameters,
      output_size,
      output_name: `2Dto3D_${sizeString}_${resolution}um.raw`,
    })
    setOutputSizeString(outputSizeString)
    resetViewID("")
  }

  const onChangeOutputName = (e) => {
    setParameters({
      ...parameters,
      output_name: e.currentTarget.value,
    })
    resetViewID("")
  }

  const onChangeImage = (event) => {
    const index = event.currentTarget.value
    changeView(index)
  }

  const LaunchButton = () => {
    if (job.status !== STATUS_RUNNING && job.status !== STATUS_WAITING)
      return (
        <button
          className="btn btn-success btn-block"
          disabled={!readyToLaunch}
          onClick={onLaunchJob}
          type="button"
        >
          Launch
        </button>
      )
    else
      return (
        <button
          className="btn btn-warning btn-block"
          onClick={onKillJob}
          type="button"
        >
          Cancel
        </button>
      )
  }

  function showHide(e) {
    e.preventDefault()
    if (navRef.current.classList.contains("collapse")) {
      navRef.current.classList.remove("collapse")
    } else {
      navRef.current.classList.add("collapse")
    }

    setCollapseAdvanceMenu((collapseAdvanceMenu) => !collapseAdvanceMenu)
  }

  const images = useMemo(() => props.project.images, [props.project.images])
  return (
    <div className="toolTab">
      <div className="parametersWrapper">
        <div className="parameters-holder">
          <h5 className="title"> Parameters </h5>
          <div className="checkbox-holder-parameter">
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_model}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-model"
            >
              <Col xs={PARAM_COLUMN}> Model </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    id="dropdown-basic"
                    size="sm"
                    variant="info"
                  >
                    {parameters.model}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-font"
                    style={{ margin: 0 }}
                  >
                    {models.map((val) => (
                      <Dropdown.Item
                        key={val}
                        onClick={() => {
                          setParameters({ ...parameters, model: val })
                        }}
                      >
                        {" " + val + " "}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_image_type}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-type"
            >
              <Col xs={PARAM_COLUMN}> Image Type </Col>
              <Col>
                <Dropdown>
                  <Dropdown.Toggle
                    className="dropdown-font custom-btn"
                    id="dropdown-basic"
                    size="sm"
                    variant="info"
                  >
                    {parameters.isGray ? "Gray" : "Segmented"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    className="dropdown-font"
                    style={{ margin: 0 }}
                  >
                    <Dropdown.Item
                      onClick={() => {
                        setParameters({ ...parameters, isGray: true })
                      }}
                    >
                      {" Gray "}
                    </Dropdown.Item>
                    <Dropdown.Item
                      onClick={() => {
                        setParameters({ ...parameters, isGray: false })
                      }}
                    >
                      {" Segmented "}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_segmentation}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-segment"
            >
              <Col xs={PARAM_COLUMN}> Segment </Col>
              <Col>
                <div className="custom-control custom-checkbox custom-parameter margin-up-down">
                  <input
                    checked={parameters.doThreshold}
                    className="custom-control-input"
                    id="doIMB"
                    onChange={() =>
                      setParameters({
                        ...parameters,
                        doThreshold: !parameters.doThreshold,
                      })
                    }
                    type="checkbox"
                  />
                  <label
                    className="label-parameter custom-control-label"
                    htmlFor="doIMB"
                  ></label>
                </div>
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_threshold}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-threshold"
            >
              <Col xs={PARAM_COLUMN}> Thresholds </Col>
              <Col>
                <input
                  className="number-parameter"
                  disabled={!parameters.doThreshold}
                  id={"threshold"}
                  onChange={onChangeThresholds}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  type="text"
                  value={parameters.thresholds}
                />
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_interpolate}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-interpolate"
            >
              <Col xs={PARAM_COLUMN}> Interpolate </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"interpolate"}
                  max={1.0}
                  min={0.2}
                  onChange={onChangeInterpolate}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.01"
                  type="number"
                  value={parameters.interpolate}
                />
              </Col>
            </Row>

            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_cell_size}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-cell-size"
            >
              <Col xs={PARAM_COLUMN}> Cell Size [um] </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"cell_size"}
                  max={1000}
                  min={0.001}
                  onChange={onChangeCellSize}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  step="0.1"
                  type="number"
                  value={parameters.cell_size}
                />
              </Col>
            </Row>
            <Row
              className="row-parameter"
              data-tooltip-content={tooltips.slicegan_output_size}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id="slicegan-output-size"
            >
              <Col xs={PARAM_COLUMN}> Ouput Cubic Size </Col>
              <Col>
                <input
                  className="number-parameter"
                  id={"output_size"}
                  onChange={onChangeOutputSize}
                  onFocus={(e) => {
                    e.target.select()
                  }}
                  type="text"
                  value={outputSizeString}
                />
              </Col>
            </Row>

            <ReactTooltip anchorId="slicegan-model" className="tooltip-msg" />
            <ReactTooltip anchorId="slicegan-type" className="tooltip-msg" />
            <ReactTooltip anchorId="slicegan-segment" className="tooltip-msg" />
            <ReactTooltip
              anchorId="slicegan-interpolate"
              className="tooltip-msg"
            />
            <ReactTooltip
              anchorId="slicegan-threshold"
              className="tooltip-msg"
            />
            <ReactTooltip
              anchorId="slicegan-cell-size"
              className="tooltip-msg"
            />
            <ReactTooltip
              anchorId="slicegan-output-size"
              className="tooltip-msg"
            />
          </div>

          <a
            aria-controls="collapseAdvanceMenu"
            aria-expanded="true"
            className="parameter_btn"
            onClick={showHide}
            role="button"
          >
            <i
              className={
                collapseAdvanceMenu
                  ? "fa fa-minus fa-fw custom-btn-icon"
                  : "fa fa-plus fa-fw custom-btn-icon"
              }
            />{" "}
            Advanced Options
          </a>
          <div className="checkbox-holder-parameter">
            <Navbar.Collapse animation="true" in={false} ref={navRef}>
              <div className="card-body-parameter card-body">
                <Row
                  className="row-parameter"
                  data-tooltip-content={tooltips.slicegan_kernel_size}
                  data-tooltip-delay-show={tooltips.delay_show_timeout}
                  id="slicegan-kernel-size"
                >
                  <Col xs={8}> Kernel Size </Col>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle
                        className="dropdown-font custom-btn"
                        id="dropdown-basic"
                        size="sm"
                        variant="info"
                      >
                        {parameters.kernel_size}
                      </Dropdown.Toggle>
                      <Dropdown.Menu
                        className="dropdown-font"
                        style={{ margin: 0 }}
                      >
                        <Dropdown.Item
                          onClick={() =>
                            setParameters({ ...parameters, kernel_size: 64 })
                          }
                        >
                          64
                        </Dropdown.Item>
                        <Dropdown.Item
                          onClick={() =>
                            setParameters({ ...parameters, kernel_size: 128 })
                          }
                        >
                          128
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>

                <Row
                  className="row-parameter"
                  data-tooltip-content={tooltips.slicegan_epochs}
                  data-tooltip-delay-show={tooltips.delay_show_timeout}
                  id="slicegan-epoch"
                >
                  <Col xs={8}> Training epochs </Col>
                  <Col>
                    <input
                      className="number-parameter"
                      id={"epochs"}
                      max="200"
                      min="10"
                      onChange={(e) => {
                        setParameters({
                          ...parameters,
                          epochs: Number(e.target.value),
                        })
                      }}
                      onFocus={(e) => {
                        e.target.select()
                      }}
                      step="1"
                      type="number"
                      value={parameters.epochs}
                    />
                  </Col>
                </Row>

                <Row className="row-parameter">
                  <Col
                    data-tooltip-content={tooltips.slicegan_gray_threshold}
                    data-tooltip-delay-show={tooltips.delay_show_timeout}
                    id="slicegan-gray-threshold"
                    xs={8}
                  >
                    {" "}
                    Gray Thresholds{" "}
                  </Col>
                  <Col>
                    <Slider
                      disableSwap
                      max={MAX_GRAY_THRESHOLD}
                      min={MIN_GRAY_THRESHOLD}
                      onChange={handleGrayThreshold}
                      step={GRAY_THRESHOLD_STEP}
                      value={[
                        parameters.gray_threshold[0],
                        parameters.gray_threshold[1],
                      ]}
                      valueLabelDisplay="auto"
                    />
                  </Col>
                </Row>
                <ReactTooltip
                  anchorId="slicegan-kernel-size"
                  className="tooltip-msg"
                />
                <ReactTooltip
                  anchorId="slicegan-epoch"
                  className="tooltip-msg"
                />
                <ReactTooltip
                  anchorId="slicegan-gray-threshold"
                  className="tooltip-msg"
                />
              </div>
            </Navbar.Collapse>
          </div>

          <h4 className="subtitle"> Input 2D Images [PNG]</h4>
          <div className="checkbox-holder-parameter custom-file">
            <div className="input-upload-files-oneline">
              <input
                accept="image/png"
                className="form-control"
                multiple
                onChange={onChangeFile}
                ref={refInputFile}
                type="file"
              />
            </div>
          </div>

          <h4 className="subtitle">Output Name</h4>
          <div className="parameter">
            <input
              className="filename"
              onChange={onChangeOutputName}
              placeholder=" Output File Name"
              type="text"
              value={parameters.output_name}
            />
          </div>

          <ImageList
            imageType={[IMAGE_TYPE, "8b", "16b"]}
            images={images}
            isCanDeleteImage={true}
            jobType={JOB_TYPE}
            onChangeImage={onChangeImage}
            pid={pid}
            title={<h4 className="subtitle"> Images [2Dto3D]</h4>}
            viewID={viewID}
          />
        </div>
        <Progress color="success" max="100" value={job.progress}>
          {job.progress}%
        </Progress>
        <br />
        <LaunchButton />
      </div>
      <div className="toolResultWrapper">
        <PrintImageSgan
          files={files}
          job={job}
          parameters={parameters}
          pid={pid}
          selectedImage={images?.[viewID] ?? null}
        />
      </div>
    </div>
  )
}

SGANTool.propTypes = {
  project: PropTypes.object.isRequired,
}

export default connect(null, {
  doJob,
  killJob,
  getJobStatus,
  getProject,
  getProjectAsync,
  uploadImage,
})(SGANTool)
