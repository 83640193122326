import "./Parameters.css"

import React from "react"

import { ExpandableList } from "../ExpandableList"

const SIZE_DEFAULT = 0.02
const NetsimParameters = ({ parameter, onChange, openAdvancedDefault }) => {
  const netsim = parameter

  return (
    <div>
      <div>
        <ExpandableList
          ariaLabel="default netsim options"
          label={"Default Options"}
          list={[
            {
              label: "Primary Drainage (PD)",
              value: netsim?.doPD ?? true,
              id: "doPD",
              type: "checkbox",
              onChange: (x) => onChange({ ...netsim, doPD: x }),
            },
            {
              label: "Imbibition",
              id: "doIMB",
              value: netsim?.doIMB ?? true,
              type: "checkbox",
              onChange: (x) => onChange({ ...netsim, doIMB: x }),
            },
            {
              label: "Scanning",
              id: "scanning",
              value: netsim?.scanning ?? false,
              type: "checkbox",
              onChange: (x) => onChange({ ...netsim, scanning: x }),
            },
            {
              label: "Injection Direction",
              value: netsim?.direction ?? "X",
              type: "select",
              id: "injectionDirection",
              selectOptions: [
                {
                  label: "X",
                  value: "X",
                },
                {
                  label: "Y",
                  value: "Y",
                },
                {
                  label: "Z",
                  value: "Z",
                },
                {
                  label: "-X",
                  value: "-X",
                },
                {
                  label: "-Y",
                  value: "-Y",
                },
                {
                  label: "-Z",
                  value: "-Z",
                },
                {
                  label: "XYZ",
                  value: "XYZ",
                },
              ],
              onChange: (x) => {
                let payload = { ...netsim, direction: x }
                if (x === "XYZ") {
                  payload = { ...payload, doIMB: false, scanning: false }
                } else {
                  payload = { ...payload, doIMB: true }
                }
                onChange({ ...netsim, ...payload })
              },
            },
            {
              label: "Min Saturation in PD",
              value: netsim?.stopSwPD ?? 0,
              step: 0.1,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 1.0)
                onChange({ ...netsim, stopSwPD: number })
              },
            },
            {
              label: "Interfacial Tension",
              value: netsim?.sow ?? 0.03,
              step: 0.01,
              min: 0,
              max: 1,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 1.0)
                onChange({ ...netsim, sow: number })
              },
            },
          ]}
          openDefault={openAdvancedDefault}
        />
      </div>
      <div>
        <ExpandableList
          ariaLabel="advanced netsim options"
          label={"Advanced Options"}
          list={[
            {
              label: "Brine Density",
              value: netsim?.densityW ?? 1001,
              step: 0.1,
              type: "number",
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 5000.0)

                onChange({ ...netsim, densityW: number })
              },
            },
            {
              label: "Oil Density",
              value: netsim?.densityO ?? 401,
              step: 0.1,
              type: "number",
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 5000.0)
                onChange({ ...netsim, densityO: number })
              },
            },
            {
              label: "Oil-Wet Fraction",
              value: netsim?.oilWetFraction ?? 0,
              step: 0.1,
              type: "number",
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 1.0)
                onChange({ ...netsim, oilWetFraction: number })
              },
            },
            {
              label: "Min Adv. Angle Water-Wet",
              value: netsim.advancingWaterMin ?? 30,
              step: 1,
              max: netsim?.advancingWaterMax,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 90.0)
                onChange({ ...netsim, advancingWaterMin: number })
              },
            },
            {
              label: "Max Adv. Angle Water-Wet",
              value: netsim.advancingWaterMax ?? SIZE_DEFAULT,
              step: 1,
              min: netsim?.advancingWaterMin ?? 0,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                number = Math.min(Math.max(number, 0.0), 90.0)
                onChange({ ...netsim, advancingWaterMax: number })
              },
            },
            {
              label: "Min Adv. Angle Oil-Wet",
              value: netsim?.advancingOilMin ?? 120,
              step: 1,
              max: netsim?.advancingOilMax ?? 150,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                if (number < 0) number = 0
                if (number > 180) number = 180
                onChange({ ...netsim, advancingOilMin: number })
              },
            },
            {
              label: "Max Adv. Angle Oil-Wet",
              value: netsim?.advancingOilMax ?? 150,
              step: 1,
              min: netsim?.advancingOilMin,
              onChange: (x) => {
                let number = Number(x)
                if (isNaN(number)) number = 0
                if (number < 0) number = 0
                if (number > 180) number = 180
                onChange({ ...netsim, advancingOilMax: number })
              },
            },
            {
              label: "Distribution",
              value: (function () {
                const distribution = netsim?.distribution ?? 1
                switch (distribution) {
                  case 0:
                    return "RANDOM"
                  case 1:
                    return "SMALL"
                  case 2:
                    return "BIG"
                  case 3:
                    return "CLAY"
                }
              })(),
              type: "select",
              id: "injectionDirection",
              selectOptions: [
                {
                  label: "RANDOM",
                  value: 0,
                },
                {
                  label: "SMALL",
                  value: 1,
                },
                {
                  label: "BIG",
                  value: 2,
                },
                {
                  label: "CLAY",
                  value: 3,
                },
              ],
              onChange: (x) => onChange({ ...netsim, distribution: +x }),
            },
          ]}
          openDefault={openAdvancedDefault}
        />
      </div>
    </div>
  )
}

export default NetsimParameters
