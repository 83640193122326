import { useEffect, useMemo, useRef, useState } from "react"

import {
  ADJUSTMENT_JOB,
  SEGMENTATION_JOB,
  STATUS_FINISHED,
} from "../../../../constants/jobnames"
import useGetImages from "../../../../hooks/useGetJobImages"
import { usePrintImageSegContext } from "../PrintImageSeg.context"

const usePrintImageSegItem = ({
  disableButtons,
  disableSlider,
  selectedImage,
  pid,
  job,
  onChangeFaceImage,
  isShowOutputImage,
  adjustmentImage,
  isShowAdjustmentOutputImage,
}) => {
  const {
    cutPlane,
    zOption,
    sliderState,
    onZoomUpdate,
    onPanUpdate,
    zoomInImage,
    zoomOutImage,
    resetImage,
    handleUpdateSliderState,
    updateCutPlane,
  } = usePrintImageSegContext()

  const [disabled, setDisabled] = useState(true)
  const { xyImageName, yzImageName, xzImageName } = useGetImages({
    selectedImage: isShowAdjustmentOutputImage
      ? adjustmentImage
      : selectedImage,
    pid,
    jobname: isShowAdjustmentOutputImage ? ADJUSTMENT_JOB : SEGMENTATION_JOB,
    isShowOutputImage,
  })
  const transWrapperRef = useRef(null)

  useEffect(() => {
    if (!job) {
      if (!disabled) setDisabled(true)

      return
    }

    if (job?.status === STATUS_FINISHED) {
      if (disabled) setDisabled(false)
    }
  }, [disabled, job, job?.status])

  useEffect(() => {
    if (!transWrapperRef.current || !zOption || !Object.keys(zOption).length)
      return
    transWrapperRef.current.setTransform(zOption.pX, zOption.pY, zOption.sC)
  }, [zOption])

  useEffect(() => {
    if (!cutPlane?.face || !onChangeFaceImage) return

    onChangeFaceImage(cutPlane?.face)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cutPlane?.face])

  const imageName = useMemo(() => {
    if (disabled) return xyImageName
    const nameImages = [xyImageName, xzImageName, yzImageName]
    return nameImages?.[cutPlane.pos] || nameImages[0]
  }, [cutPlane.pos, disabled, xyImageName, xzImageName, yzImageName])

  return {
    transWrapperRef,
    disabled,
    onZoomUpdate,
    onPanUpdate,
    sliderState,
    cutPlane,
    imageName,
    disableButtons,
    disableSlider,
    resetImage,
    zoomInImage,
    zoomOutImage,
    handleUpdateSliderState,
    updateCutPlane,
    isShowOutputImage,
  }
}

export default usePrintImageSegItem
