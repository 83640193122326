import { configureStore } from "@reduxjs/toolkit"

import projectReducer from "../actions/projectSlice"
import workflowSlice from "../actions/workflowSlice"

export default configureStore({
  reducer: {
    project: projectReducer,
    workflow: workflowSlice,
  },
})
