import { useDispatch } from "react-redux"

import { deleteRockType } from "../../../../../../actions/projectSlice"

export const useDeleteRockType = ({ project }) => {
  const dispatch = useDispatch()

  const handleDeleteRockType = (rId) => {
    if (!rId) {
      alert("No RockType ID provided for deletion.")
      return
    }
    dispatch(deleteRockType({ pid: project.pid, rId }))
  }

  return { handleDeleteRockType }
}
