import { PM_JOB, SEGMENTATION_JOB } from "../../constants/jobnames"

export const PMConstants = {
  MIN_NORM: 0,
  MAX_NORM: 65535,
  STEP_NORM: 1000,
  JOB_PREFIX: "pm_",
  INPUT_TYPE: "16b",
  JOB_TYPE: PM_JOB,
  POLLING_TIME: 3000,
  PARAM_COLUMN: 6,
  THRESHOLD_MIN: 20000,
  THRESHOLD_MAX: 5000,
}

export const PMReference = {
  PM: {
    name: "Porosity Map",
    value: PM_JOB,
  },
  SEG: {
    name: "Segmentation",
    value: SEGMENTATION_JOB,
  },
}
