import React, { useId } from "react"
import { CheckCircle, CloudOff, MinusCircle } from "react-feather"
import UseAnimations from "react-useanimations"
import activity from "react-useanimations/lib/activity"
import alert from "react-useanimations/lib/alertTriangle"
import infinity from "react-useanimations/lib/infinity"

import {
  STATUS_FAILED,
  STATUS_FINISHED,
  STATUS_KILLED,
  STATUS_RUNNING,
  STATUS_WAITING,
} from "../constants/jobnames"
import { tooltips } from "../constants/tooltips"

const JobIcon = ({ image, status }) => {
  const id = useId()
  const suffixId = image?.iid ?? id
  switch (status) {
    case STATUS_RUNNING: {
      return (
        <UseAnimations
          animation={activity}
          className="icon green-icon"
          data-tooltip-content={tooltips.status_running}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
          id={"status_running_" + suffixId}
          size={18}
        />
      )
    }
    case STATUS_FAILED: {
      return (
        <UseAnimations
          animation={alert}
          className="icon red-icon"
          data-tooltip-content={tooltips.status_failed}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
          id={"status_failed_" + suffixId}
          size={20}
        />
      )
    }
    case STATUS_FINISHED: {
      return (
        <CheckCircle
          className="icon green-icon"
          data-tooltip-content={tooltips.status_finished}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
          id={"status_finished_" + suffixId}
          size={18}
        />
      )
    }
    case STATUS_KILLED: {
      return (
        <MinusCircle
          className="icon gray-icon"
          data-tooltip-content={tooltips.status_killed}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
          id={"status_killed_" + suffixId}
          size={18}
        />
      )
    }
    case STATUS_WAITING: {
      return (
        <UseAnimations
          animation={infinity}
          className="icon green-icon"
          data-tooltip-content={tooltips.status_waiting}
          data-tooltip-delay-show={tooltips.delay_show_timeout}
          id={"status_waiting_" + suffixId}
          size={18}
        />
      )
    }
    default: {
      return <CloudOff className="icon darkgray-icon" size={18} />
    }
  }
}

export default JobIcon
