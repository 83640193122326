import React from 'react'
import { Maximize, ZoomIn, ZoomOut } from "react-feather"
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch"

import { PlaneSelector } from '../../PlaneSelector'
import VerticalSlider from '../../VerticalSlider/VerticalSlider'
import ImageWrapper from '../ImageWrapper/ImageWrapper.view'
import usePrintImageIO from './PrintImageIO.hook'
const MAX_SCALE_ZOOM_IN = 10

const PrintImageIO = (props) => {
    const { 
        job,
        transWrapperRef,
        disabled,
        sliderState,
        cutPlane,
        imageName,
        disableButtons, 
        disableSlider,
        resetImage,
        zoomInImage,
        zoomOutImage,
        handleUpdateSliderState,
        updateCutPlane,
        onZoomUpdate,
        onPanUpdate,
    } = usePrintImageIO(props)

  return (
    <ImageWrapper job={job} title="2D Viewer">
        <div className="image-box" disabled={disabled}>
            <div className="image-frame-wrapper">
            {!disabled ? (
                <TransformWrapper
                centerOnInit={false}
                centerZoomedOut={false}
                doubleClick={{
                    disabled: true,
                    mode: "reset"
                }}
                initialPositionX={0}
                initialPositionY={0}
                initialScale={1}
                limitToBounds={false}
                maxPositionX={0}
                maxPositionY={0}
                maxScale={MAX_SCALE_ZOOM_IN}
                minPositionX={0}
                minPositionY={-1000}
                minScale={1}
                onPanning={onPanUpdate}
                onZoom={onZoomUpdate}
                panning={{
                    lockAxisX: false, //(isMaxScale) ? true : false,
                    velocityEqualToMove: true,
                    velocityDisabled: false
                }}
                ref={transWrapperRef}
                >
                {() => (
                    <div className="image-frame" disabled={disabled}>
                    {disableButtons !== true ? (
                        <div className="zoom-buttons-holder">
                        <button className="zoom-button zoom-in" onClick={(e)=>{
                            e.stopPropagation()
                            zoomInImage()
                        }}>
                            <ZoomIn className="ml-2 mr-2 mt-1 mb-1" size={20} />
                        </button>
                        <button className="zoom-button zoom-out" onClick={(e)=>{
                            e.stopPropagation()
                            zoomOutImage()
                        }}>
                            <ZoomOut className="ml-2 mr-2 mt-1 mb-1" size={20} />
                        </button>
                        <button className="zoom-button reset" onClick={resetImage}>
                            <Maximize className="ml-2 mr-2 mt-1 mb-1" size={20} />
                        </button>
                        </div>
                    ) : null}
                    {disableSlider !== true ? (
                        <div className="slider-holder">
                            <VerticalSlider 
                             onChange={handleUpdateSliderState}
                             useDebounced={true}
                            />
                        </div>
                    ) : null}
                    <TransformComponent>
                        <img
                        alt=""
                        className="image"
                        src={imageName}
                        style={{
                            filter: `brightness(${sliderState.brightness}) contrast(${sliderState.contrast})`
                        }}
                        />
                    </TransformComponent>
                    </div>
                )}
                </TransformWrapper>
            ) : (
                <div className="image-frame" disabled={disabled}>
                    <img alt="new" className="image" disabled={true} src={require("../../../images/noImage.png")} />
                </div>
            )}
            </div>
        </div>
        <PlaneSelector currentCutPlane={cutPlane} disabled={disabled} updatePlane={updateCutPlane} />
    </ImageWrapper>
  )
}

export default PrintImageIO