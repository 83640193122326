import React from "react"
import { confirmAlert } from "react-confirm-alert"
import { CheckCircle, CloudOff, MinusCircle, Trash2 } from "react-feather"
import { connect } from "react-redux"
import { toast } from "react-toastify"
import { Tooltip as ReactTooltip } from "react-tooltip"
import UseAnimations from "react-useanimations"
import activity from "react-useanimations/lib/activity"
import alert from "react-useanimations/lib/alertTriangle"
import infinity from "react-useanimations/lib/infinity"

import { deleteJob } from "../actions/projectSlice"
import {
  STATUS_FAILED,
  STATUS_FINISHED,
  STATUS_KILLED,
  STATUS_RUNNING,
  STATUS_WAITING,
} from "../constants/jobnames"
import { tooltips } from "../constants/tooltips"

const ImageList = (props) => {
  const title = props?.title
  const pid = props.pid
  const images = props.images
  const jobType = props.jobType
  const imageType = props.imageType
  const viewID = props.viewID
  const isCanDeleteImage = props.isCanDeleteImage

  const handleDeleteImage = (imageInfo) => {
    const jid = imageInfo?.jobs?.[jobType]?.jid
    const iid = imageInfo?.iid

    const isValid = iid && jid && pid
    if (!isValid) {
      toast.error("Missing info to delete job!")
      return
    }

    confirmAlert({
      title: "Deleting Job - " + imageInfo.filename ?? "",
      message: "Are you sure to do this?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            const dataDelete = {
              pid,
              iid,
              jid,
              jobname: jobType,
            }

            props.deleteJob(dataDelete)
          },
        },
        {
          label: "No",
        },
      ],
    })
  }

  const JobIcon = (props) => {
    const image = props.image

    const jid = image?.jobs?.[jobType]?.jid
    const jobStatus = image?.jobs?.[jobType]?.status
    let Icon = <MinusCircle className="icon gray-icon" size={18} />

    if (jobStatus) {
      switch (jobStatus) {
        case STATUS_RUNNING: {
          Icon = (
            <UseAnimations
              animation={activity}
              className="icon green-icon"
              data-tooltip-content={tooltips.status_running}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_running_" + image.iid}
              size={20}
            />
          )
          break
        }
        case STATUS_FAILED: {
          Icon = (
            <UseAnimations
              animation={alert}
              className="icon red-icon"
              data-tooltip-content={tooltips.status_failed}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_failed_" + image.iid}
              size={22}
            />
          )

          break
        }
        case STATUS_FINISHED: {
          Icon = (
            <CheckCircle
              className="icon green-icon"
              data-tooltip-content={tooltips.status_finished}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_finished_" + image.iid}
              size={18}
            />
          )

          break
        }
        case STATUS_KILLED: {
          Icon = (
            <MinusCircle
              className="icon gray-icon"
              data-tooltip-content={tooltips.status_killed}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_killed_" + image.iid}
              size={18}
            />
          )

          break
        }
        case STATUS_WAITING: {
          Icon = (
            <UseAnimations
              animation={infinity}
              className="icon green-icon"
              data-tooltip-content={tooltips.status_waiting}
              data-tooltip-delay-show={tooltips.delay_show_timeout}
              id={"status_waiting_" + image.iid}
              size={20}
            />
          )

          break
        }
        default: {
          Icon = <CloudOff className="icon darkgray-icon" size={18} />
        }
      }
    }

    return (
      <>
        {Icon}
        {![STATUS_RUNNING, STATUS_WAITING].includes(jobStatus) &&
          jid &&
          isCanDeleteImage && (
            <Trash2
              className="icon icon-red ml-2"
              onClick={() => handleDeleteImage(image)}
              size={18}
            />
          )}
      </>
    )
  }

  const filterImage = (iid) => {
    const isArrayType = Array.isArray(imageType)
    if (isArrayType) {
      const includesType = imageType.includes(images[iid].type)
      const jobs = images[iid].jobs
      const isExistSgan = jobs && jobs.sgan
      return includesType && isExistSgan
    }

    return (
      (!imageType &&
        images[iid].type !== "network" &&
        images[iid].type !== "folder") ||
      images[iid].type === imageType
    )
  }

  const imageFiltered = Object.keys(images).filter(filterImage)
  const sizeImageFiltered = imageFiltered.length

  if (sizeImageFiltered <= 0) return <></>

  return (
    <>
      {title && sizeImageFiltered > 0 ? title : ""}
      <div className="parameter">
        {imageFiltered.map((iid) => (
          // Object.keys(images).map((iid) => (
          <div className="form-check inline-parameters" key={iid}>
            <input
              checked={viewID === iid}
              className="form-check-input"
              id={iid}
              name="inlineMaterialRadiosExample"
              onChange={props.onChangeImage}
              type="radio"
              value={iid}
            />
            <label className="form-check-label bounded-label" htmlFor={iid}>
              {" "}
              {images[iid].name}
            </label>
            <div className="icons-holder">
              <JobIcon image={images[iid]} />
              <ReactTooltip
                anchorId={"status_failed_" + iid}
                className="tooltip-msg"
              />
              <ReactTooltip
                anchorId={"status_finished_" + iid}
                className="tooltip-msg"
              />
              <ReactTooltip
                anchorId={"status_killed_" + iid}
                className="tooltip-msg"
              />
              <ReactTooltip
                anchorId={"status_running_" + iid}
                className="tooltip-msg"
              />
              <ReactTooltip
                anchorId={"status_waiting_" + iid}
                className="tooltip-msg"
              />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default connect(null, {
  deleteJob,
})(ImageList)
