import React from 'react'

import ImageIcon from './ImageIcon/ImageIcon.view'

const ImageWrapper = ({ job, title, children }) => {

    return (
        <div className="image-holder">
            <div className="inline-title">
            <ImageIcon job={job} />
            <h4 hidden={!title} > {title} </h4>
            </div>
            { children }
        </div>
    )
}

export default ImageWrapper