import React from "react"
import { ChevronUp } from "react-feather"

import FloatingButtonStyle from "./style.module.css"

const FloatingButtonToTop = () => {
  const handleScrollToTop = () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" })
  }
  return (
    <div className={FloatingButtonStyle.wrapper}>
      <button
        className={FloatingButtonStyle["floating-button"]}
        onClick={handleScrollToTop}
      >
        <ChevronUp />
      </button>
    </div>
  )
}

export default FloatingButtonToTop
