import "react-confirm-alert/src/react-confirm-alert.css"
import "./Project.css"

import React, { useEffect, useMemo, useState } from "react"
import { Button, Dropdown, Nav, Navbar } from "react-bootstrap"
import { confirmAlert } from "react-confirm-alert"
import { flushSync } from "react-dom"
import {
  Archive,
  Camera,
  Codepen,
  Codesandbox,
  Crop,
  Eye,
  Grid,
  Layers,
} from "react-feather"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import Rodal from "rodal"

import { deleteProject, uploadProject } from "../actions/projectSlice"
import { createNewWorkflow } from "../actions/workflowSlice"
import { WORKFLOW_TYPE } from "../constants/workflow"
import ConsoleHelper from "../helpers/ConsoleHelper"
import CropTool from "./CropTool"
import EmailHolder from "./EmailHolder"
import InputFile from "./InputFile"
import { NetsimTool } from "./NetsimTool"
import { NetworkTool } from "./NetworkTool"
import { PMTool } from "./PMTool"
import SegmentationTool from "./SegmentationTool"
import SGANTool from "./SGANTool"
import SuperResolutionTool from "./SuperResolutionTool"
// import UseAnimations from "react-useanimations"
import WorkflowBar from "./WorkflowBar"

const Project = () => {
  const dispatch = useDispatch()
  const project = useSelector((state) => state.project.currentProject)
  const workflows = useSelector((state) => state.workflow.listWorkflow)

  const [activeIndex, setIndex] = useState(1)
  const [viewNewWindow, setNewWindow] = useState(false)
  const [newName, setNewName] = useState(project === null ? "" : project.name)
  const [newDescription, setNewDescription] = useState(
    project === null ? "" : project.description,
  )
  const [access, setAccess] = useState(
    project === null ? "Private" : project.accessibility,
  )
  const [coverFile, setCoverFile] = useState(
    project === null ? null : { name: project.coverFile },
  )
  const [users, setUsers] = useState([])
  const author = project === null ? "" : project.author

  const usersString = useMemo(
    () => JSON.stringify(project?.users || []),
    [project?.users],
  )

  useEffect(() => {
    const _users = JSON.parse(usersString)
    setUsers(_users)
  }, [usersString])

  const imagesWorkflow = useMemo(() => {
    let imagesProject = []
    if (project?.images && Object.keys(project.images)?.length) {
      imagesProject = Object.values(project.images)
        .filter((e) => e.type !== "folder")
        ?.map((e) => {
          const { filename, iid } = e
          return { filename, iid }
        })
    }

    return imagesProject

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(project?.images)])

  const openNewWindow = () => {
    ConsoleHelper("Updating project settings", project)
    if (project !== null) {
      flushSync(() => {
        setNewName(project.name)
        setNewDescription(project.description)
        setCoverFile({ name: project.coverFile })
        setAccess(project.accessibility)
      })
    }
    setNewWindow(true)
  }

  const handleIndex = (id) => {
    if (activeIndex === id) setIndex(0)
    else setIndex(id)
  }

  const submitNewProject = () => {
    // Check values
    if (newName.length < 3) {
      toast.error("Project Name should be larger than 3")
      return
    }
    if (coverFile !== null && coverFile.name !== project.coverFile) {
      if (coverFile.type !== "image/png" && coverFile.type !== "image/jpeg") {
        toast.error("Cover Image should be PNG/JPEG/JPG")
        return
      }
    }
    if (users.length < 1) {
      toast.error("Users can't be empty")
      setUsers(project.users)
      return
    }
    if (!users.includes(author)) {
      toast.error("Not possible to remove Author")
      setUsers(project.users)
      return
    }
    // Check for changes
    let hasChanged =
      newName !== project.name ||
      newDescription !== project.description ||
      coverFile.name !== project.coverFile ||
      access !== project.accessibility ||
      JSON.stringify(users) !== JSON.stringify(project.users)
    if (!hasChanged) {
      toast.error("No new settings for Project")
      return
    }

    const data = new FormData()
    data.append("pid", project.pid)
    data.append("name", newName)
    data.append("description", newDescription)
    data.append("accessibility", access)
    data.append("users", users)
    if (coverFile !== null && coverFile.name !== project.coverFile) {
      data.append("file", coverFile)
      data.append("coverFile", coverFile.name)
    }

    let payload = {
      data,
    }
    dispatch(uploadProject(payload))
    setNewWindow(false)
  }

  const confirmDeleteProject = () => {
    confirmAlert({
      title: "Deleting Project - " + project.name,
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => dispatch(deleteProject({ pid: project.pid })),
        },
        {
          label: "No",
        },
      ],
    })
  }

  const changeCover = (e) => {
    setCoverFile(e.currentTarget.files[0])
  }

  const handleCreateNewWorkflow = () => {
    const defaultWorkflowName = `W-${(workflows?.length ?? 0) + 1}`
    const defaultWorkflowType = WORKFLOW_TYPE.MULTI_INPUTS

    const payload = {
      projectId: project._id,
      data: {
        name: defaultWorkflowName,
        type: defaultWorkflowType,
      },
    }
    dispatch(createNewWorkflow(payload))
  }

  const handleOnChange = ({ target: { value } }) => {
    setAccess(value)
  }

  if (project !== null) {
    return (
      <div className="project-container containerApp">
        <Navbar className="projects-bar" expand="lg">
          <Navbar.Text className="project-title"> {project.name} </Navbar.Text>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="project-navbar-nav">
            <Nav className="mr-auto nav-container">
              <Nav.Item
                active={activeIndex === 1 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(1)}
              >
                <Archive className="icon" size={15} />
                I/O
              </Nav.Item>

              <Nav.Item
                active={activeIndex === 2 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(2)}
              >
                <Crop className="icon" size={15} />
                Crop
              </Nav.Item>

              <Nav.Item
                active={activeIndex === 3 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(3)}
              >
                <Grid className="icon" size={15} />
                SR
              </Nav.Item>

              <Nav.Item
                active={activeIndex === 4 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(4)}
              >
                <Camera className="icon" size={15} />
                Seg
              </Nav.Item>

              <Nav.Item
                active={activeIndex === 5 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(5)}
              >
                <Layers className="icon" size={15} />
                2Dto3D
              </Nav.Item>
              <Nav.Item
                active={activeIndex === 8 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(8)}
              >
                <Codepen className="icon" size={15} />
                PM
              </Nav.Item>
              <Nav.Item
                active={activeIndex === 6 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(6)}
              >
                <Codesandbox className="icon" size={15} />
                Netex
              </Nav.Item>
              <Nav.Item
                active={activeIndex === 7 ? "true" : "false"}
                className="nav-link-div row"
                onClick={() => handleIndex(7)}
              >
                <Codepen className="icon" size={15} />
                Netsim
              </Nav.Item>
            </Nav>

            <Nav className="mr-sm-2 nav-container nav-config">
              <div className="d-flex align-items-center gap-1">
                <Eye size={25} />
                <div>{project.views}</div>
              </div>
              <Button
                className="add-workflow-button"
                onClick={handleCreateNewWorkflow}
              >
                <i className="fa fa-plus fa-fw" />
              </Button>
              <Dropdown align="start">
                <Dropdown.Toggle
                  className=""
                  id="dropdown-custom-components"
                  variant="secondary"
                >
                  <i className="fa fa-ellipsis-v fa-fw" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-menu-right">
                  <Dropdown.Item onClick={openNewWindow}>
                    {" "}
                    Settings{" "}
                  </Dropdown.Item>
                  <Dropdown.Item onClick={confirmDeleteProject}>
                    {" "}
                    <span className="text-danger">Remove Project</span>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        <div className="tools-holder">
          {/* {ConsoleHelper("In ProjectPage ", project)} */}
          {activeIndex === 1 ? <InputFile project={project} /> : null}
          {activeIndex === 2 ? <CropTool project={project} /> : null}
          {activeIndex === 3 ? <SuperResolutionTool project={project} /> : null}
          {activeIndex === 4 ? <SegmentationTool project={project} /> : null}
          {activeIndex === 5 ? <SGANTool project={project} /> : null}
          {activeIndex === 6 ? <NetworkTool project={project} /> : null}
          {activeIndex === 7 ? <NetsimTool project={project} /> : null}
          {activeIndex === 8 && <PMTool project={project} />}
        </div>

        <WorkflowBar images={imagesWorkflow} projectId={project._id} />

        {/* Edit Project Menu */}
        <Rodal
          className="newWindow"
          customStyles={{ height: "auto", bottom: "auto", top: "30%" }}
          onClose={() => setNewWindow(false)}
          visible={viewNewWindow}
        >
          <h1 className="title"> Edit Project </h1>
          <div className="container">
            <hr />
            <div className="parameter">
              <input
                className="name"
                onChange={(e) => setNewName(e.target.value)}
                placeholder=" Name"
                type="text"
                value={newName}
              />
              <textarea
                className="description"
                onChange={(e) => setNewDescription(e.target.value)}
                placeholder=" Description "
                type="text"
                value={newDescription}
              />
              <div className="custom-file">
                <input
                  className="custom-file-input"
                  id="inputGroupFile01"
                  onChange={changeCover}
                  type="file"
                />
                <label className="custom-file-label" htmlFor="inputGroupFile01">
                  {" "}
                  {coverFile === null ||
                  (coverFile.name !== undefined && coverFile.name === "")
                    ? "Cover Picture"
                    : coverFile.name}{" "}
                </label>
              </div>
              <div className="custom-users">
                <EmailHolder setUsers={setUsers} users={users} />
              </div>
              <div className="select-wrapper">
                <div className="select-title">Accessibility</div>
                <div className="select">
                  <select onChange={handleOnChange} value={access}>
                    <option value="Private">Private</option>
                    <option value="Public">Public</option>
                    <option value="Company Public">Company Public</option>
                  </select>
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div className="button-holder">
            <Button
              className="button"
              onClick={submitNewProject}
              variant="primary"
            >
              {" "}
              OK{" "}
            </Button>
            <Button
              className="button"
              onClick={() => setNewWindow(false)}
              variant="secondary"
            >
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Rodal>
      </div>
    )
  } else {
    return (
      <div className="project-container">
        <Navbar bg="light" className="projects-bar" expand="lg">
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Navbar.Text className="project-title">
                {"Loading ..."}
              </Navbar.Text>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default Project
